import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SharedModule } from '../../../common.module';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { MatTableDataSource } from '@angular/material/table';
import { RstDashboardService } from '../../../_services/rst-dashboard.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ItemsDialogComponent } from '../../items-dialog/items-dialog.component';
import { Notyf } from "notyf";
import { CalculationsService } from '../../../_services/calculations.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { KotListComponent } from "../../../../stories/stories-module/kot-list/kot-list.component";
import { ReceiptDialogComponent } from '../../receipt-dialog/receipt-dialog.component';
import { Subject, takeUntil } from 'rxjs';

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});

@Component({
  selector: 'app-take-away',
  standalone: true,
  imports: [
    SharedModule,
    KotListComponent
  ],
  templateUrl: './take-away.component.html',
  styleUrl: './take-away.component.css'
})
export class TakeAwayComponent {
  @Input() table_id!: number;
  @Input() tableInfo!: any;
  tableObj: any
  inputValue: string = "";
  _generating_sales_order: boolean = false;
  errorMessage: string | null = null;
  listNumber: any[] = [];
  itemsArr: any = [];
  isLoading: boolean = false;
  soIdArr: any[] = [];
  disposallist: any;
  model_kot: any = { trans_date: new Date(), net_amount: 0 };
  model: any = {};
  serviceChargeAmount : any;
  disposalItemlist: any;
  notesValueForEdit: any;
  currency: any;
  isServiceCharge: boolean = false;
  refund_amt: any = 0;
  isEbillingEnable: any = "false";
  waitforTakeaway: boolean = false;
  dueInvoiceObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = [
    "customer_name",
    "display_trans_no",
    "net_amount",
    "advance",
  ];
  tableInfoDineIn: any;
  kotArr: any;
  isLocal: any;
  table_name: any;
  allowChangeRate: boolean = false;
  optionarray_amount: any;
  options_array: any;
  checkedVariantAmount: any;
  checkedVariant: any;
  isTransactionGSTslab: boolean = false;
  waitforkot: boolean = false;
  isEditMode: any;
  editContactId: any;
  private destroy$ = new Subject<void>();
  data: any;
  waitforinvoice: boolean = false;
  non_receivable: boolean = false;
  showValidationInput: boolean = false;
  formSubmitted: boolean = false;
  transDataObj: any;
  invoice_no: any;
  ebillData: any;
  showValidationDiscount: boolean = false;
  otpInvalid: boolean = false;
  otpVerified: boolean = false;
  mapArr: any;
  serviceChargeValue: any;
  isAutoFocus: boolean = false;
  isdiscountFocused: boolean = false;
  itemArr: any;
  gstSlab: any;
  KOT_data: any;
  take_away: boolean = true
  dine_in: boolean = true;
  order_tab: any = "Dine-In";
  datareceipt: any;
  dueInvoice: any;



  constructor(
    private apiService: RstDashboardService,
    public dialog: MatDialog,
    private calculationService: CalculationsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['table_id']) {
    }
    if (changes['tableInfo'] && changes['tableInfo'].currentValue) {
      this.tableInfoDineIn = this.tableInfo; // Assign tableInfo to tableInfoDineIn
      // Call load() here after tableInfoDineIn is assigned
      this.load();
    }
  }

  ngOnInit() {
    this.apiService.addItemToTakeAwayCart({})
    this.apiService.getItemToTakeAwayCart()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result.item_id) {
          this.addItemCardClicked(result);
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetData() {
    this.itemsArr = [];
    this.soIdArr = [];
    this.kotArr = [];
    this.requestReset();
  }


  @Output() resetRequested = new EventEmitter<any>();

  requestReset() {
    this.resetRequested.emit({ reason: 'User clicked reset button' }); // Emit event with optional data
  }

  findAccSetting() {
    // Store account mapping list in mapArr for easier access
    this.mapArr = this.tableInfo.accountMapList;

    // Check and set Transaction GST slab setting
    const transactionGstSlab = this.mapArr.find((e: any) => e.key === "Transaction_gstslab");
    this.isTransactionGSTslab = transactionGstSlab?.checkbox_value || false;

    // If Transaction GST slab is enabled, set the tax slab value in the form
    if (this.isTransactionGSTslab) {
      const gstSlabEntry = this.mapArr.find((i: any) => i.key === "gstslab_value");
      if (gstSlabEntry) {
        this.form.get("taxslab")?.setValue(gstSlabEntry.template_value);
        this.gstSlab = gstSlabEntry?.template_value;
      }
    }

    // Check and set Service Charge setting
    const serviceChargeEntry = this.mapArr.find((e: any) => e.key === "service_charge");
    this.isServiceCharge = serviceChargeEntry?.checkbox_value || false;

    // If Service Charge is enabled, process service charge settings
    if (this.isServiceCharge) {
      const serviceChargeValueEntry = this.mapArr.find(
        (i: any) => i.key === "service_charge_value"
      );

      if (serviceChargeValueEntry) {
        this.serviceChargeValue = serviceChargeValueEntry.text_value;
        this.form.get("service_charge_per")?.setValue(serviceChargeValueEntry.text_value);
        this.onServicePerOnLoad(this.serviceChargeValue)
      }
    }

    // Check and set Auto Focus setting
    const autoFocusEntry = this.mapArr.find(
      (i: any) => i.key === "Enable_Auto_Focus"
    );
    this.isAutoFocus = autoFocusEntry?.checkbox_value || false;
  }


  load() {
    this.initializeFormGroup()
    this.dineInData();
  }

  addItemCardClicked(i: any) {
    const data = { item: i, status: "add", source: "Take-Away" };
    const index = this.itemsArr.findIndex(
      (itm: any) => itm.item_id === i.item_id && itm.item_name == i.item_name
    );
    if (index !== -1) {
      (i.options && i.options.length > 0) || (i.variant_groups && i.variant_groups.length) > 0
        ? this.openCustomization(data)
        : this.addItemToKot(i);
    } else {
      (i.options && i.options.length) > 0 || (i.variant_groups && i.variant_groups.length) > 0
        ? this.openCustomization(data)
        : this.addItemToKot(i);
    }
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dineInData() {

    // this.apiService.getreceiptData(this.table_id).subscribe(
    //   (result: any) => {
    //     this.data = result;
    //     this.dueInvoice = result;
    //     this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
    //     this.dueInvoiceObj.sort = this.sort;
    //     this.dueInvoiceObj.paginator = this.paginator;
    //   })
    if (this.tableInfo.unpaidInvoiceList) {
      this.data = this.tableInfo.unpaidInvoiceList;
      this.dueInvoice = this.tableInfo.unpaidInvoiceList;
      this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
      this.dueInvoiceObj.sort = this.sort;
      this.dueInvoiceObj.paginator = this.paginator;
      if (this.dueInvoice && this.dueInvoice.length > 0) {
      }
    } else {
      this.dueInvoiceObj = new MatTableDataSource();
    }
    this.isLocal = this.tableInfo.isLocal;
    localStorage.setItem("isLocal", JSON.stringify(this.isLocal));

    //---------tableName------------------
    this.table_name = this.tableInfo.tableName
      ? this.tableInfo.tableName
      : null;
    //---------allowChangeRate------------------
    this.allowChangeRate = this.tableInfo.allowChangeRate ? true : false;

    this.calculateAmtWithTax()

  }

  addItemToKot(item: any) {

    if (!item.qty) {
      item.qty = 1
    }
    // Make sure KOT List is initialized
    if (!this.itemsArr) this.itemsArr = [];

    // Check if item.item_id exist in KOT list
    var result_items = this.itemsArr.filter((search_item: any) => {
      return search_item.item_id === item.item_id;
    });
    var found_item = null;
    var new_item_Added = false;
    if (result_items && result_items.length > 0) found_item = result_items[0];

    if (!found_item) {
      found_item = item;
      new_item_Added = true;

      found_item = { ...found_item };
      if (!found_item.amount) {

        found_item.amount = +item.s_rate;
      } else {
        found_item.amount = item.amount;
      }

      found_item.instruction = "";

    } else {
      new_item_Added = false;
      // If Item already exist in KOT then increase qty and calcluate new amount
      found_item.qty = found_item.qty + item.qty;
      found_item.amount = found_item.qty * parseFloat(item.s_rate);
    }

    if (new_item_Added) this.itemsArr.unshift(found_item);

    this.calculateAmtWithTax();
    this.findAccSetting();
  }


  calculateAmtWithTax(): void {
    // Ensure KOT_data exists
    if (!this.itemsArr || !Array.isArray(this.itemsArr)) {
      console.error('KOT_data is not defined or is not an array.');
      return;
    }

    const itemDetails = this.itemsArr && this.itemsArr.length ? this.itemsArr : [];

    let serviceCharge = this.serviceChargeAmount
    
    let gstSlabValue = this.gstSlab;

    const body = {
      items_details: itemDetails,
      discount_amt: this.form.value.discount_amt ? this.form.value.discount_amt : 0,
      other1_amt: this.form.value.other1_amt ? this.form.value.other1_amt : 0,
      other2_amt: this.form.value.other2_amt ? this.form.value.other2_amt : 0,
      taxslab: gstSlabValue,
      taxable_amt: 0,
      service_charge: serviceCharge,
      total_amount: 0,
      net_amount: 0,
      roundoff_amt: 0,
      gross_amount: 0,
      total_tax: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      vat_per: 0,
      vat_amt: 0,
      tax_slab: 0,
      sign: 0,
    };


    try {
      // Calculate results using the service
      const result = this.calculationService.calculateAmtWithTransTax(body);

      if (!result || Array.isArray(result)) {
        // console.error('Calculation result is undefined, null, or an array.');
        return;
      }

      // Mapping result values to form controls
      const formControlsMapping: Record<string, number> = {
        total_amount: result.total_amount,
        net_amount: result.net_amount,
        roundoff_amt: result.roundoff_amt,
        gross_amount: result.gross_amount,
        total_tax: result.total_tax && result.total_tax.toFixed(2),
        sgst_per: result.sgst_per,
        sgst_amt: result.sgst_amt,
        cgst_per: result.cgst_per,
        cgst_amt: result.cgst_amt,
        igst_per: result.igst_per,
        igst_amt: result.igst_amt,
        vat_per: result.vat_per,
        vat_amt: result.vat_amt,
        taxable_amt: result.taxable_amt,
        service_charge: result.service_charge
      };

      // Update form controls dynamically
      Object.entries(formControlsMapping).forEach(([controlName, value]) => {
        const control = this.form.get(controlName);
        if (control) {
          control.setValue(value, { emitEvent: false }); // Prevent unnecessary form value emissions
        } else {
          console.warn(`Form control "${controlName}" is not defined.`);
        }
      });
    } catch (error) {
      console.error('Error calculating amount with tax:', error);
    }
  }


  // main form for all transactions details
  form: FormGroup = new FormGroup({
    customer_name: new FormControl("Name"),
    email: new FormControl(null),
    address: new FormControl(null),
    trans_no: new FormControl("Auto Generated"),
    trans_date: new FormControl(new Date()),
    ledger_id: new FormControl(null),
    table_id: new FormControl(null),
    gstin: new FormControl(null),
    total_amount: new FormControl(0),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0),
    cash: new FormControl(0),
    card: new FormControl(0),
    upi: new FormControl(0),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    balance: new FormControl(0),
    contact_no: new FormControl(null),
    taxslab: new FormControl(0),
    vattaxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    vat_per: new FormControl(0),
    vat_amt: new FormControl(0),
    gross_amount: new FormControl(0),
    item_qty: new FormControl(null),
    item_rate: new FormControl(null),
    item_amt: new FormControl(null),
    cash_receive: new FormControl(0),
    upi_receive: new FormControl(0),
    instruction: new FormControl(""),
    service_charge: new FormControl(0),
    service_charge_per: new FormControl(0),
  });

  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      customer_name: "Name",
      email: null,
      address: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      table_id: null,
      gstin: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      balance: 0,
      contact_no: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      vat_per: 0,
      vat_amt: 0,
      gross_amount: 0,
      taxslab: 0,
      vattaxslab: 0,
      item_qty: null,
      item_rate: null,
      item_amt: null,
      cash_receive: 0,
      upi_receive: 0,
      instruction: null,
      service_charge: 0,
      service_charge_per: 0,
    });
    this.itemsArr = [];
    this.calculateAmtWithTax();
    this.soIdArr = [];
    if (this.kotArr) {
      this.kotArr
        .filter((x: any) => x.added === true)
        .forEach((x: any) => this.kotArr.splice(this.kotArr.indexOf(x), 1));
    }
    window.scroll(0, 0);
  }

  //on enter customer phone no invoice
  onInput(event: any): void {
    event.preventDefault();
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, "");

    // Limit the length to 10 digits
    if (value && value.length > 10) {
      value = value.slice(0, 10);

      this.errorMessage = "Number should not exceed 10 digits.";
    } else {
      this.errorMessage = null;
    }

    this.inputValue = value;
    inputElement.value = value; // Update the input field value

    this.searchByPhone(value);
  }

  // on select customer phone from list no invoice
  handleInputChange(value: string | null | undefined): void {
    if (!value || value.trim() === "") {
      this.listNumber = []; // Clear listNumber if input value is empty or null/undefined
    } else {
      this.apiService.searchNumber(value.trim()).subscribe(
        (response: any[]) => {
          this.listNumber = response || []; // Directly assign the response if it's an array, otherwise, clear the list
        },
        (error: any) => {
          console.error("API error:", error);
          this.listNumber = []; // Optionally, clear the list on error
        }
      );
    }
  }

  setInputValue(value: string): void {
    // this.inputValue = value;
  }

  handleEnterKey(event: any): void {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of Enter key
      this.searchByPhone((event.target as HTMLInputElement).value); // Trigger search by phone
    }
  }

  resetForm(): void {
    this.form.patchValue({
      customer_name: "",
      email: "",
      gstin: "",
    });
  }

  searchByPhone(phone: string): void {

    this.inputValue = phone;
    this.apiService.fetchContactByPhone(phone).subscribe(
      (response: any) => {
        if (response.success && response.contact) {
          this.form.patchValue({
            ...this.form.value,
            customer_name: response.contact.name,
            email: response.contact.email_id,
            address: response.contact.shipping_address1,
          });
          // }
          this.isEditMode = true;
          this.editContactId = response.contact.id;
        } else {
          this.resetForm();
          this.isEditMode = false;
          this.editContactId = null;
        }
      },
      (error: any) => {
        console.error("Error searching contact by phone", error);
      }
    );
  }


  onChangeItemQty(e: any, i: any) {
    let number: number = 0;
    let optionAmount: number = 0;
    if (this.itemsArr && this.itemsArr[i]) {
      let positiveQty = parseFloat(e.target.value) || 0;

      this.itemsArr[i].qty = positiveQty;
      if (this.itemsArr[i].optionAmount) {
        optionAmount = parseFloat(this.itemsArr[i].optionAmount);
      } else {
        optionAmount = number;
      }

      this.itemsArr[i].amount =
        (parseFloat(this.itemsArr[i].s_rate) + optionAmount) *
        parseFloat(this.itemsArr[i].qty);
      this.calculateAmtWithTax()
    } else {
      console.error(
        `Item at index ${i} is undefined or itemsArr is not initialized.`
      );
    }
  }

  // inv item quentity increase
  increase_qty(e: any, i: any) {
    let number: number = 0;
    let optionAmount: number = 0;
    let Quantity = parseFloat(e.qty);
    if (Quantity === 0.1) {
      this.itemsArr[i].qty = Quantity + 0.9;
    } else if (Quantity >= 1) {
      this.itemsArr[i].qty = Quantity + 1;
    } else {
      this.itemsArr[i].qty = Quantity + 1;
    }
    if (this.itemsArr[i].optionAmount) {
      optionAmount = parseFloat(this.itemsArr[i].optionAmount);
    } else {
      optionAmount = number;
    }
    this.itemsArr[i].amount =
      (optionAmount + parseFloat(this.itemsArr[i].s_rate)) *
      parseFloat(this.itemsArr[i].qty);
    this.calculateAmtWithTax()

  }

  // inv  item quentity decrease
  decrease_qty(e: any, i: any) {

    let number: number = 0;
    let optionAmount: number = 0;
    if (parseFloat(e.qty) <= 0.1) {
      this.itemsArr[i].qty = 0.1;
    } else if (e.qty === 1 || parseFloat(e.qty) === 1.0) {
      this.itemsArr[i].qty = (e.qty - 0.9).toFixed(1);
    } else if (parseFloat(e.qty) < 1) {
      // Check if currentQty is close to 0.9 or 1 and set it to 0.1 if true
      this.itemsArr[i].qty = parseFloat(e.qty) - 1;
      if (
        parseFloat(this.itemsArr[i].qty) <= 0.0 ||
        this.itemsArr[i].qty <= 0
      ) {
        this.itemsArr[i].qty = 0.1;
      }
    } else {
      this.itemsArr[i].qty = (e.qty - 1).toFixed(1);
    }
    if (this.itemsArr[i].optionAmount) {
      optionAmount = parseFloat(this.itemsArr[i].optionAmount);
    } else {
      optionAmount = number;
    }
    this.itemsArr[i].amount =
      (optionAmount + this.itemsArr[i].s_rate) *
      parseFloat(this.itemsArr[i].qty);
    this.calculateAmtWithTax()

  }

  //on item rate change on search item pannel
  onChangeItemRate(e: any, i: any) {
    this.itemsArr[i].s_rate = e.target.value;
    this.itemsArr[i].amount = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    this.calculateAmtWithTax()

  }

  //convert string to float
  convertFloat(val: any) {
    return parseFloat(val);
  }

  //for deleting invoice items
  onDeleteItem(i: any) {
    this.itemsArr.splice(i, 1);
    this.form.get("dis_per")!.setValue(0);
    this.form.get("discount_amt")!.setValue(0);
    this.form.get("service_charge_per")!.setValue(0); // Reset discount percentage to 0
    this.serviceChargeAmount = 0
    this.calculateAmtWithTax()
  }

  // update addons and variant to any items.
  updateCustomisation(item: any) {
    let updateItem: any;
    if (this.itemsArr && this.itemsArr.length > 0) {
      // updateItem = this.KOT_data.filter((e: any) => e.item_id == itm.item_id && e.item_code == itm.item_code);
      updateItem = this.itemsArr.filter((search_item: any) => {
        let isChildOptionsMatch = true; // Default to true
        let isVariantsMatch = true; // Default to true

        // Check base item details
        const isBasicMatch =
          search_item.item_id === item.item_id &&
          search_item.optionAmount === item.optionAmount &&
          search_item.checkedVariantAmount === item.checkedVariantAmount;

        // Check childOptions if they exist
        if (
          item.optionsData &&
          item.optionsData.length > 0 &&
          item.optionsData[0].childOptions
        ) {
          isChildOptionsMatch = item.optionsData[0].childOptions.every(
            (childOpt: any, index: number) => {
              return (
                search_item.optionsData &&
                search_item.optionsData.length > 0 &&
                search_item.optionsData[0].childOptions &&
                search_item.optionsData[0].childOptions[index] &&
                search_item.optionsData[0].childOptions[index].title ===
                childOpt.title
              );
            }
          );
        }

        // Check variant groups if they exist
        if (
          item.checkedVariant &&
          item.checkedVariant.length > 0 &&
          item.checkedVariant[0].variants
        ) {
          isVariantsMatch = item.checkedVariant[0].variants.every(
            (variant: any, index: number) => {
              return (
                search_item.checkedVariant &&
                search_item.checkedVariant.length > 0 &&
                search_item.checkedVariant[0].variants &&
                search_item.checkedVariant[0].variants[index] &&
                search_item.checkedVariant[0].variants[index].title ===
                variant.title
              );
            }
          );
        }

        // Return true if all conditions match
        return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
      });
      const data = { item: updateItem[0], status: "update", source: "Take-Away" };

      this.openCustomization(data);
    }
  }

  openCustomization(e: any) {
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      const item = result.item;
      if (result.status === "add") {
        this.optionarray_amount = item.optionAmount;
        this.options_array = item.optionsData;
        this.checkedVariantAmount = item.checkedVariantAmount;
        this.checkedVariant = item.checkedVariant;
        this.addItemToTakeAway(item);
      } else {
        this.updateOrder(item);
      }
    });
  }

  // add items to take away
  addItemToTakeAway(item: any) {
    const existingItemIndex = this.itemsArr.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.checkedVariant &&
        item.checkedVariant.length > 0 &&
        item.checkedVariant[0].variants
      ) {
        isVariantsMatch = item.checkedVariant[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.checkedVariant &&
              search_item.checkedVariant.length > 0 &&
              search_item.checkedVariant[0].variants &&
              search_item.checkedVariant[0].variants[index] &&
              search_item.checkedVariant[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });
    // let updatedTitle = this.updateItemTitle(item);

    if (existingItemIndex === -1) {
      item.qty = item.qty || 1;
      item.amount = this.calculateItemAmount(item);
      // item.item_code = updatedTitle;
      this.itemsArr.push({ ...item });
    } else {
      this.itemsArr[existingItemIndex].qty += item.qty || 1;
      this.itemsArr[existingItemIndex].amount = this.calculateItemAmount(
        this.itemsArr[existingItemIndex]
      );
    }
    this.calculateAmtWithTax();
    this.findAccSetting();
  }


  calculateItemAmount(item: any) {
    const baseAmount = item.qty * item.s_rate;
    const optionsAmount = item.qty * this.optionarray_amount;
    return baseAmount + optionsAmount;
  }

  // update order like if any addons or variant is changed or added
  updateOrder(item: any) {
    this.optionarray_amount = item.optionAmount;
    this.options_array = item.optionsData;
    this.checkedVariantAmount = item.checkedVariantAmount;
    this.checkedVariant = item.checkedVariant;
    item.amount =
      item.checkedVariantAmount > 0
        ? (item.checkedVariantAmount + item.optionAmount) *
        (item.qty > 0 ? item.qty : 1)
        : item.optionAmount > 0
          ? item.optionAmount + item.s_rate * item.qty
          : item.qty > 0
            ? item.s_rate * item.qty
            : item.s_rate;
    if (this.isTransactionGSTslab === false) {
      if (item.tax_mode === "GST") {
        if (this.isLocal) {
          item.sgst_per = item.taxslab / 2;
          item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
          item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
          item.cgst_per = item.taxslab / 2;
          item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
          item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
        } else {
          item.igst_per = item.taxslab;
          item.igst_amt = item.amount * (item.igst_per / 100) || 0;
          item.igst_amt = Math.round(item.igst_amt * 100) / 100;
        }

        item.total_tax =
          item.sgst_amt || 0 + item.cgst_amt || 0 + item.igst_amt || 0;
      } else {
        item.vat_per = item.taxslab;
        item.vat_amt = item.amount * (item.igst_per / 100) || 0;
        item.vat_amt = Math.round(item.igst_amt * 100) / 100;
        item.total_tax = item.vat_amt || 0;
      }
      item.total = item.amount + item.total_tax;
      item.instruction = this.form && this.form.value.instruction;
    }
    this.updateOrderItem(item);
    this.findAccSetting();
  }

  // update order item
  updateOrderItem(item: any) {

    const index = this.itemsArr.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.checkedVariant &&
        item.checkedVariant.length > 0 &&
        item.checkedVariant[0].variants
      ) {
        isVariantsMatch = item.checkedVariant[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.checkedVariant &&
              search_item.checkedVariant.length > 0 &&
              search_item.checkedVariant[0].variants &&
              search_item.checkedVariant[0].variants[index] &&
              search_item.checkedVariant[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    if (index !== -1) {
      this.itemsArr[index] = item;
    }
    this.calculateAmtWithTax();
    this.findAccSetting();
  }

  // on add instruction from take away
  instrucctiondata(item: any, n: any) {
    this.itemsArr = this.itemsArr.filter((data: any) => {
      if (item.item_id == data.item_id) {
        data.instruction = n.value;
      }
      return data;
    });
  }

  showdisposalitems(data: any) {

  }

  addItemsone(item: any) { }

  // on focus on discount field
  onDiscountFocus(isFocused: boolean) {
    this.isdiscountFocused = isFocused;
    if (!isFocused) {
      const discountControl: any = this.form.get("dis_per");
      if (discountControl.value === 0 || discountControl.value === null) {
        discountControl.setValue(null);
      }
    }
  }

  onServiceFocus(isFocused: boolean) {
    this.isdiscountFocused = isFocused;
    if (!isFocused) {
      const serviceChargeControl: any = this.form.get("service_charge_per");
      if (serviceChargeControl.value === 0 || serviceChargeControl.value === null) {
        serviceChargeControl.setValue(null);
      }
    }
  }

  // on discount per change
  onDiscountPer(event: any) {
    const totalAmount = this.form.get("total_amount")?.value || 0;
    const discountPer = this.form.get("dis_per")?.value || 0;
    const discountAmount = (totalAmount * discountPer) / 100;
    this.form.get("discount_amt")?.setValue(discountAmount);
    const amountAfter = totalAmount - discountAmount;

  const serviceChargePer = this.form.get("service_charge_per")?.value || 0; // Parse the service charge percentage

  const serviceChargeAmount = (amountAfter * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount
    this.updateGrossAmount();
  }

  onServicePerOnLoad(serviceChargePer: number) {
  
    const totalAmount = this.form.get("total_amount")?.value || 0;
    const serviceChargeAmount = (totalAmount * serviceChargePer) / 100;
    this.serviceChargeAmount = serviceChargeAmount
    this.calculateAmtWithTax(); // Update gross amount after service charge change
  }

 // Handle change in service charge percentage
onServicePer(event: any) {
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const discountAmount = this.form.get("discount_amt")?.value || 0;

  const amountAfter = totalAmount - discountAmount;

  const serviceChargePer = parseFloat(event.target.value) || 0; // Parse the service charge percentage
  this.form.get("service_charge_per")?.setValue(serviceChargePer); // Set the service charge percentage in the form
  const serviceChargeAmount = (amountAfter * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount.toFixed(2)
  this.form.get("service_charge")?.setValue(serviceChargeAmount); // Set the service charge amount in the form
  this.updateGrossAmount(); // Update gross amount after service charge change
}

  // update gross amount
  updateGrossAmount(): void {
    const totalAmount = this.form.get("total_amount")?.value || 0;
    const discountAmount = this.form.get("discount_amt")?.value || 0;
    const serviceChargeAmount = this.form.get("service_charge")?.value || 0;
    const taxAmount = this.form.get("total_tax")?.value || 0;
    const otherCharges =
      (this.form.get("other1_amt")?.value || 0) +
      (this.form.get("other2_amt")?.value || 0);
    const grossAmount = totalAmount - discountAmount + taxAmount + otherCharges + serviceChargeAmount;
    const formattedGrossAmount = parseFloat(grossAmount).toFixed(2);
    this.form.get("gross_amount")?.setValue(formattedGrossAmount);
    this.updateNetAmount();
  }

  // // update net amount
  updateNetAmount(): void {
    const grossAmount = this.form.get("gross_amount")?.value || 0;
    const roundOff = this.form.get("roundoff_amt")?.value || 0;
    const netAmount = grossAmount + roundOff;
    this.form.get("net_amount")?.setValue(netAmount);
    this.calculateAmtWithTax();
  }

 // Handle discount amount change and reset discount percentage
onDiscountAmt(event: any) {
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const discountAmount = this.form.get("discount_amt")?.value || 0;
  const amountAfter = totalAmount - discountAmount;
  const serviceChargePer = this.form.get("service_charge_per")?.value || 0;
  const serviceChargeAmount = (amountAfter * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount.toFixed(2)
  this.form.get("dis_per")!.setValue(0); // Reset discount percentage to 0
  this.calculateAmtWithTax(); // Recalculate amount with the new discount value
}

  // on add other price.
  onOtherPrice() {
    if (this.form?.value?.total_amount) {
      this.calculateAmtWithTax();
    }
  }

  // on change cash card or upi
  onCashOrCard() {
    var total_advance =
      this.form?.value.cash + this.form.value.card + this.form.value.upi;
    var balance_amt = this.form.value.net_amount - total_advance;
    this.form.get("advance")?.setValue(total_advance);
    this.form.get("balance")?.setValue(balance_amt);
    if (this.form?.value.cash_receive >= this.form?.value.cash) {
      this.refund_amt = this.form.value.cash_receive - this.form.value.cash;
    }
  }

  // on change cash card or upi
  refreshPayment() {
    if (this.form?.value.cash_receive >= this.form?.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
    }
    this.onCashOrCard();
  }

  // on change cash card or upi
  onCashReceive() {
    if (this.form?.value.cash_receive >= this.form?.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
      this.onCashOrCard();
    }
  }

  // to open receipt dialog
  receiptDialog(e: any) {
    const dialogRef: MatDialogRef<ReceiptDialogComponent> = this.dialog.open(
      ReceiptDialogComponent,
      {
        data: e.display_trans_no,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.load();
      }
    });

  }


  // to download pdf
  downloadClick(dataObj: any, transNo: any) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }

  // crm save contect form form
  saveContact(): void {
    if (this.form.valid) {
      const contactData = {
        name: this.form?.value.customer_name,
        email_id: this.form?.value.email,
        contact_no: this.form?.value.contact_no,
        gstin: this.form?.value.gstin,
        shipping_address1: this.form?.value.address,
      };

      if (this.isEditMode && this.editContactId) {
        this.apiService
          .editContact({ ...contactData, id: this.editContactId })
          .subscribe(
            (response: any) => {
              if (response.success) {
                // this.resetForm();
              }
            },
            (error: any) => {
              console.error("Error editing contact", error);
            }
          );
      } else {
        this.apiService.addContact(contactData).subscribe(
          (response: any) => {
            if (response.success) {
              // this.resetForm();
            }
          },
          (error: any) => {
            console.error("Error adding contact", error);
          }
        );
      }
    }
  }



  validateEmailAndMobile() {
    const email = this.form?.value.email;
    const contact_no = this.form?.value.contact_no;

    contact_no && contact_no.length !== 10
      ? notyf.error("Contact number must be exactly 10 digits")
      : null;

    const customername = this.form?.value.customer_name;
    if (!customername) {
      notyf.error("Customer name is required");
      return false;
    }

    // Email validation (basic regex)
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (email && !emailPattern.test(email)) {
      notyf.error("Please enter a valid email address");
      return false;
    }
    return true;
  }

  createTakeAwayOrder() {

    this.saveContact();

    let validItems: any[] = []
    this.waitforTakeaway = true;

    this.ebillData = this.tableInfo.ebill;

    // Ensure itemsArr exists and all items have valid amounts
    if (this.itemsArr.length > 0) {
      const index = this.itemsArr.findIndex((itm: any) => typeof itm.qty !== 'number');

      if (index !== -1) {
        // Convert qty to number at the found index
        this.itemsArr[index].qty = parseFloat(this.itemsArr[index].qty);

      }
      validItems = this.itemsArr.filter((item: any) => item.amount > 0);
    }

    if (this.form.valid && this.validateEmailAndMobile()) {
      this.invoice_no = null;
      this.form.get("trans_no")!.setValue(this.invoice_no);

      this.transDataObj = this.form.value;
      this.transDataObj.items_details = validItems;
      this.transDataObj.soIdArr = [...new Set(this.soIdArr)];
      this.transDataObj.ledger_id = 0;
      this.transDataObj.table_id = this.table_id;
      this.transDataObj.sale_type = "cash";
      this.transDataObj.orderMode = 'Take Away';
      this.transDataObj.non_Receivable = this.non_receivable;
      this.transDataObj.eBillData = this.ebillData ? this.ebillData : null;
      this.transDataObj.shipping_address1 = this.form?.value.address;

      this.apiService.takeAwayOrder(this.transDataObj).subscribe(
        (result: any) => {
          if (result.success) {

            result.data.forEach((item: any) => {
              // Assuming result is the response object
              const kotPdfData = item.isKot ? item.kotPdf.data : item.invoicePdf.data;

              // Convert the 'data' array to a Uint8Array
              const pdfArray = new Uint8Array(kotPdfData);

              // Create a Blob with the correct type
              const blob = new Blob([pdfArray], { type: "application/pdf" });

              // Create a temporary link element for the download
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              if (item.isKot) {
                link.download = `kot_${item.max_trans_value}.pdf`;

              } else {
                link.download = `invoice_${item.display_trans_no}.pdf`;
              }

              // Trigger the download
              link.click();

              // Revoke the object URL to free memory
              window.URL.revokeObjectURL(link.href);
            });


            notyf.success("Take-Away Order added successfully");
            this.waitforTakeaway = false;
            this.initializeFormGroup();
            this.load();
            this.requestReset()

          } else {
            window.scroll(0, 0);
            notyf.error(result.message);
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.waitforTakeaway = false;
          }
        },
        (error: any) => {
          this.initializeFormGroup();
          notyf.error("unable to add invoice");
          this.waitforinvoice = false;
          this.non_receivable = false;
          this.showValidationInput = false;
          this.showValidationDiscount = false;
          this.formSubmitted = false;
          this.waitforTakeaway = false;
        }
      );
    }
  }


  billsdata: any = [];
  wait: boolean = false;
  paymentMode: any;
  receipt: any = [];

  onReceipt(row: any, mode: string) {
    this.isLoading = true;
    // Assign the full net amount to the selected payment mode
    if (mode === "cash") {
      this.form.controls['cash'].setValue(row.net_amount);
      this.form.controls['card'].setValue(0);
      this.form.controls['upi'].setValue(0);
    } else if (mode === "card") {
      this.form.controls['cash'].setValue(0);
      this.form.controls['card'].setValue(row.net_amount);
      this.form.controls['upi'].setValue(0);
    } else if (mode === "upi") {
      this.form.controls['cash'].setValue(0);
      this.form.controls['card'].setValue(0);
      this.form.controls['upi'].setValue(row.net_amount);
    }

    const amountReceived =
      (this.form.controls['cash'].value || 0) +
      (this.form.controls['card'].value || 0) +
      (this.form.controls['upi'].value || 0);

    if (row.net_amount !== amountReceived) {
      this.isLoading = false;
      notyf.error("Paid Amount Should Be Equal To Due Amount");
      return;
    }
    
    this.data = row;
    this.wait = true;
    this.paymentMode = mode; // Set payment mode based on the button clicked

    if (this.data) {
      this.billsdata.push({
        transaction_id: this.data.transaction_id,
        trans_date: this.data.trans_date,
        trans_no: this.data.trans_no,
        display_trans_no: this.data.display_trans_no,
        customer_name: this.data.customer_name && this.data.customer_name,
        table_id: this.data.table_id,
        table_name: "T2",
        net_amount: this.data.net_amount,
        discount_amt: this.data.discount_amt,
        advance: amountReceived,
        paid: amountReceived.toString(),
        due: amountReceived, // No due amount since full amount is paid
        bill_amt: this.data.net_amount,
      });

      this.receipt.push({
        bills: this.billsdata,
        ledger_id: 17,
        ledger_id2: 1,
        payment_mode: this.paymentMode,
        total_amount: amountReceived,
        trans_date: this.data.trans_date,
        trans_no: null,
        trans_type: "Receipt",
        transaction_id: this.data.transaction_id,
        cash: this.form.controls['cash'].value,
        card: this.form.controls['card'].value,
        upi: this.form.controls['upi'].value,
      });  

      this.apiService.addReceipt(this.receipt[0]).subscribe(
        (result: any) => {
          if (result.success) {
            this.receipt.trans_no = result.max_trans_value;
            this.receipt.transaction_id = result.transaction_id;
            this.receipt.trans_type = "Receipt";
            this.receipt.bills = null;
            this.printClick();
            this.data = undefined;
            this.billsdata = [];
            this.receipt = [];
            notyf.success("Receipt created successfully");
            this.wait = false;
            setTimeout(() => {
              this.isLoading = false; // Delay reset by 3 seconds
            }, 3000);
            this.load();
          } else {
            notyf.error(result.message);
            this.data = undefined;
            this.billsdata = [];
            this.receipt = [];
            this.wait = false;
            this.isLoading = false;
          }
        },
        (error) => {
          notyf.error("Unable to create Receipt");
          this.data = undefined;
          this.billsdata = [];
          this.receipt = [];
          this.wait = false;
          this.isLoading = false;
        }
      );
    } else {
      notyf.error("No invoice Found");
      this.wait = false;
      this.isLoading = false;
    }
  }

  printClick() {
    this.model.trans_data = this.receipt;
    this.model.trans_type = "Receipts";
    this.apiService.downloadPdf(this.model).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "recepit_data" + new Date().toLocaleDateString() + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }

}
