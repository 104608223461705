<div id="rst-invoice" class="panelcard">
  <form #f="ngForm" [formGroup]="form">
    <div class="col">
      <div class="row">
        <div class="col-md-8 col-12 tab-details content-scroll">

          <app-category-card-button (TransferDialog)="openTransferDialog()" (redirectToKey)="redirectToKeyboard()"
            (clearTab)="clearTable()" (clearwater)="clearwaterwaiter()" (allowLoginChange)="onAllowLogin($event)"
            [table_name]="table_name" [tableCapacity]="tableCapacity"></app-category-card-button>

          <!-- category----------------------------------------------------------- -->
          <div class="catogaries" style="overflow: auto">

          </div>

          <app-category-cart [titleSize]="'14px'" [titleWeight]="'700'" [titleColor]="'#04030f'" [categories]="catArr"
            (cardSelected)="showitems($event)" (tableObj)="(tableObj)"></app-category-cart>

          <!-- items search--------------------------------------------------- -->
          <div style="margin-top: 5px">
            <div class="container">
              <div class="main-container">
                <div class="col-md-5">
                  <mat-form-field class="contract-full-width">
                    <mat-label>Search Item</mat-label>
                    <input type="text" aria-label="Number" matInput [formControl]="itemControl" [matAutocomplete]="auto"
                      (keyup)="search($event)" (focus)="onFocus($event)" (keydown.enter)="onEnterKey($event)" #sitm />
                    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                      (optionSelected)="itemSelection($event)">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        <!-- Your option template -->
                        <span class="item-font">({{ option.item_code }})</span>
                        <span class="item-font"> {{ option.item_name }}</span>
                        <br />
                        <small>
                          <span class="item-subtitle">category: </span>
                          <span *ngIf="option.category_name" class="item-rate">{{ option.category_name }}</span>
                        </small>
                        <small>
                          <span class="item-rate-font">SRate: </span>
                          <span *ngIf="option.s_rate" class="item-rate">{{
                            option.s_rate | number : "1.2-2"
                            }}</span>
                        </small>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <!-- Other columns ... -->
                <div class="col-md-2">
                  <div class="qty-control" style="display: flex; align-items: center">
                    <!-- Decrement Button -->
                    <button (click)="decrementQty()" class="qty-btn"
                      style="width: 25px; height: 25px; border-radius: 25%">
                      -
                    </button>

                    <!-- Quantity Input Field -->
                    <mat-form-field class="contract-full-width">
                      <mat-label>Qty:</mat-label>
                      <input class="text-center" matInput type="text" formControlName="item_qty"
                        (keyup)="calculateItemAmt($event)" (change)="calculateItemAmt($event)"
                        (focus)="onQtyFocus($event)" (blur)="onQtyFocus(null)" (keydown.enter)="onEnterKey($event)"
                        min="0.1" step="1.0" #qty1 />
                    </mat-form-field>

                    <!-- Increment Button -->
                    <button (click)="incrementQty()" class="qty" style="width: 25px; height: 25px; border-radius: 25%">
                      +
                    </button>
                  </div>
                </div>

                <div class="col-md-2">
                  <mat-form-field class="contract-full-width">
                    <mat-label>Rate:</mat-label>
                    <input matInput type="text" formControlName="item_rate" [readonly]="!allowChangeRate"
                      (keyup)="calculateItemAmt($event)" (keydown.enter)="onEnterKey($event)" #s_rate1 />
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field class="contract-full-width">
                    <mat-label>Amount:</mat-label>
                    <input matInput type="number" formControlName="item_amt" (keydown.enter)="onEnterKey($event)"
                      readonly />
                  </mat-form-field>
                </div>
                <div class="col-md-1 pt-3">
                  <button type="button" class="btn btn-sm del-btn" (click)="onAddItem()"
                    (keydown.enter)="onEnterKey($event)">
                    <mat-icon>add_circle_outline</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <app-sub-category-card [subCategories]="sub_category_data" [items_data]="items_data"
              (cardSelected)="showSubitems($event.sub_category_id)" (addItem)="addItemCardClicked($event)"
              [currency]="currency" [selectedCategoryName]="selectedCategoryName" [titleSize]="'14px'"
              [titleWeight]="'700'" [titleColor]="'#04030f'" [showPrice]="true"></app-sub-category-card>
          </div>
        </div>

        <div class="col-md-4 col-12 bill-details content-scroll">
          <mat-tab-group (selectedTabChange)="onChange($event)" #tabGroup class="tabdesign">
            <mat-tab label="Dine-In" style="font-weight: 600" class="billing-tab-ui" *ngIf="dine_in === true">
              <!-- dine in component  -->
              <app-dine-in [table_id]="table_id" [tableInfo]="tableInfo"
                (resetRequested)="handleReset($event)"></app-dine-in>

            </mat-tab>

            <mat-tab label="Take Away" style="font-weight: 600" class="billing-tab-ui">
              <!-- take away component  -->
              <app-take-away [table_id]="table_id" [tableInfo]="tableInfo"></app-take-away>

            </mat-tab>

            <!-- //collapse ----------------------------------------------------------------------->
          </mat-tab-group>
        </div>
      </div>
    </div>

    <!-- Popup -->
    <div *ngIf="showPopup" class="popup-screen" (click)="closePopup($event)">
      <div class="popup-content" (click)="$event.stopPropagation()">
        <h3>Search Items</h3>
        <div class="row search-items-food">
          <div class="col-md-5">
            <mat-form-field class="contract-full-width">
              <input id="popup-search-input" type="text" placeholder="Search Item" aria-label="Number" matInput
                [formControl]="itemControl" [matAutocomplete]="auto" (keyup)="searchItems($event)" />
              <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn"
                (optionSelected)="selectItem($event)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  <!-- Your option template -->
                  <span class="item-font">({{ option.item_code }})</span>
                  <span class="item-font"> {{ option.item_name }}</span>
                  <br />
                  <small>
                    <span class="item-subtitle">category: </span>
                    <span *ngIf="option.category_name" class="item-rate">{{
                      option.category_name
                      }}</span>
                  </small>
                  <small>
                    <span class="item-rate-font">SRate: </span>
                    <span *ngIf="option.s_rate" class="item-rate">{{
                      option.s_rate | number : "1.2-2"
                      }}</span>
                  </small>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="contract-full-width">
              <input matInput type="text" placeholder="Qty:" formControlName="item_qty"
                (keyup)="calculateItemAmt($event)" (change)="calculateItemAmt($event)" min="1" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="contract-full-width">
              <input matInput type="text" placeholder="Rate:" formControlName="item_rate" [readonly]="!allowChangeRate"
                (keyup)="calculateItemAmt($event)" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="contract-full-width">
              <input matInput type="number" placeholder="Amount:" formControlName="item_amt" readonly />
            </mat-form-field>
          </div>
          <div class="col-md-1 pt-3">
            <button type="button" class="btn btn-sm del-btn" (click)="onAddItem()">
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </div>
        </div>
        <div>
          <h4>Order List</h4>
          <ul>
            <li *ngFor="let item of orderList">
              {{ item.item_name }} - {{ item.qty }} - {{ item.amount }}
            </li>
          </ul>
        </div>
        <div class="shortcut-keys-note">
          <h4>Shortcut Keys:</h4>
          <p><strong>Ctrl + Alt + O</strong> - Open/Close this popup</p>
          <p><strong>Ctrl + Alt + M</strong> - Move items to KOT list</p>
          <p><strong>Esc</strong> - Close this popup</p>
        </div>
      </div>
    </div>
  </form>
</div>