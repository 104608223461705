<div
  class="header-container"
  [ngStyle]="{ 'background-color': backgroundColor }"
>
  <div class="header-left">
    <p class="welcome">{{ welcomeMessage }}</p>
    <p class="profile-name">{{ profileName }}</p>
  </div>

  <div class="header-middle">
    <div class="search-bar-container">
      <input
        type="text"
        class="search-bar input-field"
        placeholder="Search..."
        [(ngModel)]="searchTerm"
        (input)="onSearchChange($event)"
        (keydown)="onKeyDown($event)"
      />
      <app-icon
        [iconName]="'Search'"
        [iconColor]="iconColor"
        [iconSize]="24"
        class="search-icon"
      ></app-icon>

      <div class="search-suggestions" *ngIf="searchTerm">
        <ul
          *ngIf="filteredData.length > 0"
          #suggestionsContainer
          class="suggestions-container"
          (scroll)="onScroll($event)"
        >
          <ng-container *ngFor="let group of filteredData">
            <li
              class="suggestion-item"
              *ngFor="let item of group.menuItem; let i = index"
              [class.active]="i === activeIndex"
              (click)="onSelectItem(item)"
              [attr.data-index]="i"
            >
              <span
                [ngStyle]="{
                  'background-color': getBackgroundColor(group.menu_group_name)
                }"
                class="group-label"
              >
                {{ group.menu_group_name }}
              </span>
              <span class="item-text">{{ item.menu_name }}</span>
            </li>
          </ng-container>
        </ul>

        <p *ngIf="dataNotFound" class="no-data">Data not found</p>
      </div>
    </div>
  </div>


  <div class="callSupport">
   
    <div class="headset">
      <span class="material-icons" style="font-size: 35px;">headset</span>
    </div>
    
  
    <div class="callFor">
      <div style="display: flex; align-items: center;">Call for support</div>
      <div style="display: flex; align-items: center; font-weight: bold;">06746752892</div>
    </div>
  </div>

  <div class="header-right">
    <div class="notification-container" (click)="onrightToolbarMenuToggle()">
      <app-icon
        [iconName]="'Notification'"
        [iconColor]="iconColor"
        [iconSize]="35"
        [iconBackground]="'#fff'"
        [isCircular]="true"
      ></app-icon>
      <div *ngIf="notificationCount > 0" class="notification-circle">
        {{ notificationCount }}
      </div>
    </div>
    <app-dropdown-menu-card
      [title]="userFullName"
      [role]="userRole"
      [isConnected]="isConnected"
      [companyName]="company_name"
      [iconColor]="'#000'"
      [iconSize]="'24px'"
      [menuItems]="getFilteredMenuItems()"
      [isCircular]="true"
      [isLabel]="false"
      (itemClicked)="onDropdownItemClicked($event)"
    ></app-dropdown-menu-card>
  </div>
</div>
