import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { DataService } from "../../data.service";
// 
import { Observable } from "rxjs";
import { map, share, startWith } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ItemsDialogComponent } from "../items-dialog/items-dialog.component";
import { KotCancelDialogComponent } from "./kot-cancel-dialog/kot-cancel-dialog.component";
import { SharedModule } from "../../common.module";
import { RstDashboardService } from "../../_services/rst-dashboard.service";
import { Notyf } from 'notyf';

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  }
});
@Component({
  selector: "app-rst-kot",
  templateUrl: "./rst-kot.component.html",
  styleUrls: ["./rst-kot.component.css"],
  standalone: true,
  imports: [
    SharedModule,
  ]
})
export class RstKotComponent implements OnInit {
  // data:any;
  // dialog:any;
  // apiService: any;
  // dialogRef:any
  constructor(
    private apiService: RstDashboardService,
    private dialogRef: MatDialogRef<RstKotComponent>,
    public dialog: MatDialog,
    // 
    // private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.currency = environment.currency;
  }
  @Output() onKotAdd: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("s_rate1") s_rate1!: ElementRef;
  @ViewChild("qty1") qty1!: ElementRef;
  @ViewChild("sitm") sitm!: ElementRef;
  @ViewChild("sitm") itemSelect!: MatSelect;

  itemsArr: any = [];
  model: any = { trans_date: new Date(), net_amount: 0 };
  itemModel: any = {};
  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<any[]> = new Observable<any>();;
  itemObj: any = [];
  isAutoFocus: boolean = false;
  isQtyFocused: boolean = false;
  currency: any;
  allowChangeRate: boolean = false;
  isTransactionGSTslab: boolean = false;
  isLocal: boolean = false;
  status: any = "add";
  ngOnInit() {
    this.load();
  }
  load() {
    const isTransaction = localStorage.getItem("isTransactionGSTslab");
    this.isTransactionGSTslab = isTransaction == "false" ? false : true;
    const isLoc = localStorage.getItem("isLocal");
    this.isLocal = isLoc == "false" ? false : true;
    this.allowChangeRate = this.data.allowChangeRate;
    // this.sitm.nativeElement.focus();
    if (this.data.kot_id) {
      this.apiService.getSalesOrder(this.data.kot_id).subscribe(
        (result: any) => {

    // Check if `items_details` exists and is an array within `result`
    if (Array.isArray(result.items_details)) {

      result.items_details.forEach((item: any) => {
        // Check if `qty` exists, is a string, and convert it to a float with one decimal place
          item.qty = parseFloat(item.qty);
        
      });
    }

    // Assign the processed result to `this.model`
    this.model = result;                
          this.itemsArr = result.items_details;
          this.calculateAmt();
        },
        (error: any) => { }
      );
    } else {
      this.apiService.getItemList().subscribe(
        (result: any) => {
          this.itemObj = result;
          this.sitm.nativeElement.focus();
          this.filteredOptions = this.itemControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filter(value))
          );
        },
        (result: any) => { }
      );
    }
  }
  //getting item active length
  getActiveItemCount() {
    return this.itemsArr.filter((item :any) => item.active).length;
  }

  _filter(value:any) {
    const filterValue = typeof value != "object" ? value.toLowerCase() : value;
    return this.itemObj.filter(
      (item:any) =>
        item.item_code.toLowerCase().includes(filterValue) ||
        item.item_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(Item:any) {
    return Item ? Item.item_name : "";
  }
  search(event:any) {
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl && this.itemControl.value && this.itemControl.value.length > 1) {
        const filter = "restaurant_menu_items";
        this.apiService
          .getCustomFilterItemList(this.itemControl.value, filter, this.data.table_id)
          .subscribe(
            (result: any) => {
              this.itemObj = result;
              this.filteredOptions = this.itemControl.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
              );
            },
            (result: any) => { }
          );
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    }
  }
  // itemSelection(event: MatAutocompleteSelectedEvent) {
  //   if (event.option.value != 0) {
  //     let item = event.option.value;  
  //     console.log("item---",item);
  //     var item_id = event.option.value ? event.option.value.item_id : undefined;
  //     let updatedTitle = this.updateItemTitle(item);
  //     var indexofItem = this.itemObj.findIndex((i:any) => i.item_id === item_id && i.item_code === updatedTitle);
  //     if (event.option.value.options.length > 0 && event.option.value.variant_groups.length > 0) {
  //       const data = { item: this.itemObj[indexofItem], status: "add" };

  //       this.openCustomization(data);
  //     } else {
  //       const isCustomised = false;
  //       this.populateItemData(this.itemObj[indexofItem], isCustomised);
  //     }

  //     // Move focus to qty1 element after item selection
  //     setTimeout(() => {
  //       if (this.qty1 && this.qty1.nativeElement) {
  //         this.qty1.nativeElement.focus();
  //       }
  //     }, 100);
  //   }
  // }
   // Updated event handler for Enter key press
   
   itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      // this.focusSetting();
      var item_id = event.option.value ? event.option.value.item_id : undefined;
      var indexofItem = this.itemObj.findIndex(
        (i: any) => i.item_id === item_id
      );

      if (
        event.option.value.options.length > 0 ||
        event.option.value.variant_groups.length > 0
      ) {
        const data = { item: this.itemObj[indexofItem], status: "add" };
        this.openCustomization(data);
      } else {
        const isCustomised = false;
        this.populateItemData(this.itemObj[indexofItem], isCustomised);
      }

      // Move focus to qty1 element after item selection
      setTimeout(() => {
        if (this.qty1 && this.qty1.nativeElement) {
          this.qty1.nativeElement.focus();
        }
      }, 0);
    }
  }
   onEnterKey(event: any) {
    // Prevent default behavior and check if the key is Enter
    if (event.key === "Enter") {
      event.preventDefault();

      // If focus is on the search input (sitm.nativeElement)
      if (event.target === this.sitm.nativeElement) {
        // Check if there is a valid item in the filteredOptions list
        this.filteredOptions.subscribe((options) => {
          if (options.length === 1) {
            // Set the selected item and focus on the quantity field
            this.itemControl.setValue(options[0]);

            setTimeout(() => {
              if (this.qty1 && this.qty1.nativeElement) {
                this.qty1.nativeElement.focus();
              }
            }, 0);
          }
        });
      } else if (event.target === this.qty1.nativeElement) {
        // If Enter is pressed while in the quantity input, add the item
        this.onAddItem(); // This method will handle adding the item to KOT
      }
    }
  }

  populateItemData(itemDetails:any, isCustomised:any) {
    itemDetails.qty = parseFloat(itemDetails.qty)
    let number:any = 1
    number = parseFloat(number)
    
    if (isCustomised) {
      const existingItemIndex = this.itemsArr.findIndex((search_item:any) => {
        let isChildOptionsMatch = true; // Default to true
        let isVariantsMatch = true; // Default to true
      
        // Check base item details
        const isBasicMatch = search_item.item_id === itemDetails.item_id &&
                             search_item.optionAmount === itemDetails.optionAmount &&
                             search_item.checkedVariantAmount === itemDetails.checkedVariantAmount;
      
        // Check childOptions if they exist
        if (itemDetails.optionsData && itemDetails.optionsData.length > 0 && itemDetails.optionsData[0].childOptions) {
          isChildOptionsMatch = itemDetails.optionsData[0].childOptions.every((childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title === childOpt.title
            );
          });
        }
      
        // Check variant groups if they exist
        if (itemDetails.variant_groups && itemDetails.variant_groups.length > 0 && itemDetails.variant_groups[0].variants) {
          isVariantsMatch = itemDetails.variant_groups[0].variants.every((variant: any, index: number) => {
            return (
              search_item.variant_groups &&
              search_item.variant_groups.length > 0 &&
              search_item.variant_groups[0].variants &&
              search_item.variant_groups[0].variants[index] &&
              search_item.variant_groups[0].variants[index].title === variant.title
            );
          });
        }
      
        // Return true if all conditions match
        return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
      });
      
        if (existingItemIndex === -1) {          
          itemDetails.qty = parseFloat(itemDetails.qty) || parseFloat(number);
          itemDetails.amount = this.calculateItemAmount(itemDetails);
          this.itemsArr.push(itemDetails);
        } else {    
          this.itemsArr[existingItemIndex].qty = parseFloat(this.itemsArr[existingItemIndex].qty)    
          this.itemsArr[existingItemIndex].qty += parseFloat(itemDetails.qty) || parseFloat(number);
          this.itemsArr[existingItemIndex].amount = this.calculateItemAmount(
          this.itemsArr[existingItemIndex]
          );
        }


    }else{
    let number:number = 1;
    this.itemModel = itemDetails;
    this.itemModel.qty = this.itemModel.qty ? parseFloat(this.itemModel.qty).toFixed(1) : number;
    const a = parseFloat(this.itemModel.qty) * this.itemModel.s_rate;
    const b = parseFloat(this.itemModel.qty) * this.optionarray_amount;
    const amount = a + b;
    this.itemModel.amount = amount ? amount : itemDetails.s_rate * itemDetails.qty;
  }
    if (this.data.isAutoFocus) {
      this.qty1.nativeElement.focus();
    }
    this.calculateAmt();
  }

   // calculate item amount
   calculateItemAmount(item: any) {
    const baseAmount = item.qty * item.s_rate;
    const optionsAmount = item.qty * this.optionarray_amount;
    return baseAmount + optionsAmount;
  }

  // populateItemData(itemDetails:any, isCustomised:any) {
  //   this.itemModel = itemDetails;
  //   const qty = itemDetails.qty ? itemDetails.qty : 1;
  //   this.itemModel.qty = qty;
  //   const a = this.itemModel.qty * this.itemModel.s_rate;
  //   const b = this.itemModel.qty * this.optionarray_amount;
  //   const amount = a + b;
  //   this.itemModel.amount = amount ? amount : itemDetails.s_rate * qty;
    
  //   if (this.data.isAutoFocus) {
  //     this.qty1.nativeElement.focus();
  //   }

  //   if (isCustomised) {
  //     this.itemModel = itemDetails.item;
  //     const qty = itemDetails.qty ? itemDetails.qty : 1;
  //     this.itemModel.qty = qty;
  //     const a = this.itemModel.qty * this.itemModel.s_rate;
  //     const b = this.itemModel.qty * this.optionarray_amount;
  //     const amount = a + b;
  //     this.itemModel.amount = amount ? amount : itemDetails.s_rate * qty;
  //     this.onAddItem();
  //   }
  // }



  onQtyFocus(e:any) {
    if (e) {
      this.isQtyFocused = true;
    } else {
      this.isQtyFocused = false;
    }
  }

  // Increment the quantity
  incrementQty() {
    // Get the current value from the form control, or default to 0 if it is empty/invalid
    
    const currentQty = parseFloat(this.itemModel.qty) || 0; 
    let newQty = 0;
  
    // Check if the current value is the minimum value (0.1), if so, increment by 0.9 to make it 1
    if (currentQty === 0.1) {
      newQty = currentQty + 0.9;
    } else {
      // For all other values, increment by 1
      newQty = currentQty + 1;
    }
  
    // Set the new quantity value in the form control and ensure it's to 1 decimal place
    this.itemModel.qty = parseFloat(newQty.toFixed(1));
    // Trigger the recalculation of the item amount
    this.calculateItemAmt({ target: { value: newQty } });
  }
  

// Decrement the quantity
decrementQty() {
  let newQty = 0;
  let subtractValue = 1;
  const currentQty = parseFloat(this.itemModel.qty) || 0; // Get the current value or default to 0
  if (currentQty <=0.1 ) { // Prevent going below zero
    newQty = 0.1; 
  }else if(currentQty === 1){
    newQty = currentQty - 0.9;

  }else if (currentQty < 1) {
     newQty = currentQty - subtractValue
     if(newQty <= 0.0 || newQty <= 0){
       newQty = 0.1
     }
  }else {
    newQty = currentQty - 1; // Decrement by 1
  }
  this.itemModel.qty = parseFloat(newQty.toFixed(1)); // Set the new value with one decimal place
    this.calculateItemAmt({ target: { value: newQty } }); // Trigger any recalculations
  }




  calculateItemAmt(event:any) {
    var qty = (parseFloat(this.itemModel?.qty)).toFixed(1);
    var s_rate = this.itemModel.s_rate;
    let Quantity = parseFloat(qty)
    if (Quantity <= 0.1) {
      Quantity = 0.1;
    }
    let a:any = (Quantity ? Quantity : 0) * (s_rate ? s_rate : 0);
    let b:any = (Quantity ? Quantity : 0) * this.optionarray_amount;
    var amount = parseFloat(a + b).toFixed(2);
    this.itemModel.amount = amount;

    if (event.key == "Enter" && !this.isQtyFocused) {
      this.onAddItem();
      this.sitm.nativeElement.focus();
    }
    if (event.key == "Enter" && this.isQtyFocused) {
      this.s_rate1.nativeElement.focus();
    }
  }


  onAddItem() {
    if (this.itemModel.qty) {
      this.itemModel.amount =
        this.itemModel.checkedVariantAmount > 0
          ? (this.itemModel.checkedVariantAmount +
            this.itemModel.optionAmount) *
          (this.itemModel.qty > 0 ? parseFloat(this.itemModel.qty) : 1)
          : this.itemModel.optionAmount > 0
            ? this.itemModel.optionAmount +
            this.itemModel.s_rate * this.itemModel.qty
            : this.itemModel.qty > 0
              ? this.itemModel.s_rate * this.itemModel.qty
              : this.itemModel.s_rate;
      if (this.isTransactionGSTslab === false) {
        if (this.itemModel.tax_mode === "GST") {
          if (this.isLocal) {
            this.itemModel.sgst_per = this.itemModel.taxslab / 2;
            this.itemModel.sgst_amt =
              this.itemModel.amount * (this.itemModel.sgst_per / 100) || 0;
            this.itemModel.sgst_amt =
              Math.round(this.itemModel.sgst_amt * 100) / 100;
            this.itemModel.cgst_per = this.itemModel.taxslab / 2;
            this.itemModel.cgst_amt =
              this.itemModel.amount * (this.itemModel.cgst_per / 100) || 0;
            this.itemModel.cgst_amt =
              Math.round(this.itemModel.cgst_amt * 100) / 100;
          } else {
            this.itemModel.igst_per = this.itemModel.taxslab;
            this.itemModel.igst_amt =
              this.itemModel.amount * (this.itemModel.igst_per / 100) || 0;
            this.itemModel.igst_amt =
              Math.round(this.itemModel.igst_amt * 100) / 100;
          }

          this.itemModel.total_tax =
            this.itemModel.sgst_amt ||
            0 + this.itemModel.cgst_amt ||
            0 + this.itemModel.igst_amt ||
            0;
        } else {
          this.itemModel.vat_per = this.itemModel.taxslab;
          this.itemModel.vat_amt =
            this.itemModel.amount * (this.itemModel.vat_per / 100) || 0;
          this.itemModel.vat_amt =
            Math.round(this.itemModel.vat_amt * 100) / 100;
          this.itemModel.total_tax = this.itemModel.vat_amt || 0;
        }
        this.itemModel.total = this.itemModel.amount + this.itemModel.total_tax;
      }
      var pos = this.itemsArr.findIndex(
        (e:any) => e.item_id === this.itemModel.item_id
      );
      if (pos == -1) {
        
          this.itemsArr.push(this.itemModel);
       
      } else {
        let number:number = 0;
        let optionAmount:number = 0;
        this.itemsArr[0].optionsData = this.options_array;
        this.itemsArr[pos].qty =
          parseFloat(this.itemsArr[pos].qty) + parseFloat(this.itemModel.qty);
        // const a =
        //   (this.itemsArr[pos].qty) *
        //   (this.itemsArr[pos].s_rate);
        // const b = (this.itemsArr[pos].qty) * this.optionarray_amount;
        // this.itemsArr[pos].amount = a + b;

        if(this.itemsArr[pos].optionAmount){
          optionAmount = parseFloat(this.itemsArr[pos].optionAmount)
        }else{
          optionAmount = number
        }

        this.itemsArr[pos].amount = (this.itemsArr[pos].s_rate + optionAmount) * parseFloat((this.itemsArr[pos].qty))
      }
      this.calculateAmt();
      this.itemModel = {};
      this.initialiseItem();
    }else{
      notyf.error("you did not enter quantity,Try Again");
      this.itemModel = {};
      this.initialiseItem()
    }
  }
  optionarray_amount: number = 0;
  options_array: any[] = [];
  checkedVariantAmount: number = 0;
  checkedVariant: any[] = [];

  openCustomization(e: any) {
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.optionarray_amount = result.optionAmount
        // this.options_array = result.optionsData
        // const isCustomised = true
        // this.populateItemData(result, isCustomised)
        const item = result.item;
        if (result.status === "add") {
          this.optionarray_amount = item.optionAmount;
          this.options_array = item.optionsData;
          this.checkedVariantAmount = item.checkedVariantAmount;
          this.checkedVariant = item.checkedVariant;
          const isCustomised = true;
          this.populateItemData(item, isCustomised);
          this.itemControl.reset();
        } else {
          this.updateOrder(item);
        }
      }
    });
  }

  updateOrder(item:any) {
    this.optionarray_amount = item.optionAmount;
    this.options_array = item.optionsData;
    this.checkedVariantAmount = item.checkedVariantAmount;
    this.checkedVariant = item.checkedVariant;
    item.amount =
      item.checkedVariantAmount > 0
        ? (item.checkedVariantAmount + item.optionAmount) *
        (parseFloat(item.qty) > 0 ? parseFloat(item.qty) : 1)
        : item.optionAmount > 0
          ? item.optionAmount + item.s_rate * item.qty
          : parseFloat(item.qty) > 0
            ? item.s_rate * parseFloat(item.qty)
            : item.s_rate;
    if (this.isTransactionGSTslab === false) {
      if (item.tax_mode === "GST") {
        if (this.isLocal) {
          item.sgst_per = item.taxslab / 2;
          item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
          item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
          item.cgst_per = item.taxslab / 2;
          item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
          item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
        } else {
          item.igst_per = item.taxslab;
          item.igst_amt = item.amount * (item.igst_per / 100) || 0;
          item.igst_amt = Math.round(item.igst_amt * 100) / 100;
        }

        item.total_tax =
          item.sgst_amt || 0 + item.cgst_amt || 0 + item.igst_amt || 0;
      } else {
        item.vat_per = item.taxslab;
        item.vat_amt = item.amount * (item.vat_per / 100) || 0;
        item.vat_amt = Math.round(item.vat_amt * 100) / 100;
        item.total_tax = item.vat_amt || 0;
      }
      item.total = item.amount + item.total_tax;
      // item.instruction = this.form.value.instruction
    }
    this.updateOrderItem(item);
  }

  updateOrderItem(item:any) {
    // const index = this.itemsArr.findIndex(
    //   (itm:any) => itm.item_id === item.item_id
    // );

    const index = this.itemsArr.findIndex((search_item:any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true
    
      // Check base item details
      const isBasicMatch = search_item.item_id === item.item_id &&
                           search_item.optionAmount === item.optionAmount &&
                           search_item.checkedVariantAmount === item.checkedVariantAmount;
    
      // Check childOptions if they exist
      if (item.optionsData && item.optionsData.length > 0 && item.optionsData[0].childOptions) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every((childOpt: any, index: number) => {
          return (
            search_item.optionsData &&
            search_item.optionsData.length > 0 &&
            search_item.optionsData[0].childOptions &&
            search_item.optionsData[0].childOptions[index] &&
            search_item.optionsData[0].childOptions[index].title === childOpt.title
          );
        });
      }
    
      // Check variant groups if they exist
      if (item.checkedVariant && item.checkedVariant.length > 0 && item.checkedVariant[0].variants) {
        isVariantsMatch = item.checkedVariant[0].variants.every((variant: any, index: number) => {
          return (
            search_item.checkedVariant &&
            search_item.checkedVariant.length > 0 &&
            search_item.checkedVariant[0].variants &&
            search_item.checkedVariant[0].variants[index] &&
            search_item.checkedVariant[0].variants[index].title === variant.title
          );
        });
      }
    
      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    if (index !== -1) {
      
      this.itemsArr[index] = item;
    }
    this.calculateAmt();
  }

  onDeleteItem(i: number) {
    this.itemsArr[i].active = false;
    this.itemsArr[i].qty = 0;
    this.itemsArr[i].s_rate = 0;
    this.itemsArr[i].amount = 0;
    this.itemsArr[i].cgst_amt = 0;
    this.itemsArr[i].cgst_per = 0;
    this.itemsArr[i].gross_amount = 0;
    this.itemsArr[i].sgst_amt = 0;
    this.itemsArr[i].sgst_per = 0;
    this.itemsArr[i].taxable_amt = 0;
    this.calculateAmt();
  }

  initialiseItem() {
    this.itemControl.setValue(null);
    this.itemObj = [];
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }
  calculateAmt() {
    this.model.net_amount = 0;
    this.itemsArr.forEach((itm:any) => {
      if (itm.active) {
        this.model.net_amount = this.model.net_amount + itm.amount;
      }
    });
    this.model.total_amount = this.model.net_amount
  }
  onFocus(e:any) {
    const filter = "restaurant_menu_items";
    this.apiService.getCustomItemList(filter).subscribe(
      (result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (result: any) => { }
    );
  }
  onClose() {
    this.dialogRef.close();
  }
  getTransNo() {
    return this.model.trans_no ? this.model.trans_no : " Auto";
  }
  // onChangeItemQtyOrRate(e, i) {
  //   const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
  //   const b = this.itemsArr[i].qty * this.optionarray_amount
  //   this.itemsArr[i].amount = a +b ;
  //   this.calculateAmt()
  // }
  // cancelKOT() {
  //   this.dialog
  //     .open(ConfirmDialogComponent, {
  //       data: "Are you sure to Cancel the KOT Details.?",
  //     })
  //     .afterClosed()
  //     .subscribe((result) => {

  //       if (result == true) {
  //         const Reason = "";
  //         this.apiService.deleteSalesOrder(this.data.kot_id, Reason).subscribe(
  //           (result: any) => {
  //             notyf.success( "KOT Canceled successfully");
  //             this.dialogRef.close("Edited");
  //           },
  //           (result: any) => {
  //             notyf.error( "unable to cancel KOT");
  //           }
  //         );
  //       }
  //     });
  // }
  cancelKOT() {
    this.dialog
      .open(KotCancelDialogComponent, {
        width: "400px",
        data: { transaction_id: this.data.kot_id },
      })
      .afterClosed()
      .subscribe((result:any) => {
        if (result.success) {
          this.model.trans_type = "SalesOrder";
          var tr_no = this.model.trans_no;
          this.downloadClick(this.model, tr_no);
          this.dialogRef.close();
        }
      });
  }
  downloadClick(dataObj:any, transNo:any) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }
  onSubmit() {
    if (this.itemsArr.length) {
      const hasInvalidAmount = this.model.items_details.some((item:any) => item.active === true && item.amount <= 0);
    
    if (hasInvalidAmount) {
      notyf.error("Quantity is not filled properly above");
      return;  // Stop further execution if validation fails
    }
      
      if (this.data.kot_id) {
        this.model.items_details = this.itemsArr;
        this.apiService.editSalesOrder(this.data.kot_id, this.model).subscribe(
          (result: any) => {
            if (result.success) {
              this.model.trans_type = "SalesOrder";
              var tr_no = this.model.trans_no;
              this.downloadClick(this.model, tr_no);
              notyf.success(
                "Sales Order Updated successfully"
              );
            } else {
              notyf.error("error");
            }
            this.dialogRef.close("Edited");
          },
          (result: any) => {
            notyf.error( "unable to update Sales Order");
          }
        );
      } else {
        this.model.items_details = this.itemsArr;
        this.model.table_id = this.data.table_id;
        this.model.ledger_id = 0;
        this.apiService.addSalesOrder(this.model)
          .subscribe((result: any) => {
            if (result.success) {
              this.model.trans_no = result.max_trans_value;
              this.model.transaction_id = result.transaction_id;
              this.model.trans_type = "SalesOrder";
              var tr_no = this.model.trans_no
              this.downloadClick(this.model,tr_no);
              this.model = {trans_date: new Date(), net_amount: 0};
              this.itemModel = {};
              this.itemsArr = [];
              this.onKotAdd.emit(true);
              notyf.success( 'New KOT added successfully');
            } else {
              notyf.error( result.message);
            }
          }, (result: any) => {
            notyf.error( 'Unable to add new KOT');
          });
      }
    } else {
      notyf.error( "Add atleast one item to continue");
    }
  }
  convertFloat(val:any) {
    return parseFloat(val);
  }

  // kot item quentity increase
  increase_qty(e: any, i: any) {
    let number:number = 0;
    let optionAmount:number = 0;

    this.itemsArr[i].qty = parseFloat(this.itemsArr[i].qty)

    if(this.itemsArr[i].qty =='' || isNaN(this.itemsArr[i].qty)){
      this.itemsArr[i].qty = 1;
    }else if(parseFloat(this.itemsArr[i].qty) <= 0.1){
      this.itemsArr[i].qty = parseFloat(this.itemsArr[i].qty) + 1;
    }else{
      this.itemsArr[i].qty = parseFloat(this.itemsArr[i].qty) + 1;
    }
    
    if(this.itemsArr[i].optionAmount){
      optionAmount = parseFloat(this.itemsArr[i].optionAmount)
    }else{
      optionAmount = number
    }

    // let b =  parseFloat(this.itemsArr[i].qty) * this.optionarray_amount;
    // let a =  parseFloat(this.itemsArr[i].qty) *  this.itemsArr[i].s_rate;
    // this.itemsArr[i].amount = a + b;
    this.itemsArr[i].amount = (optionAmount + this.itemsArr[i].s_rate) * parseFloat(this.itemsArr[i].qty)
    this.calculateAmt();
  }

  // kot item quentity decrease
  decrease_qty(e: any, i: any) {
    let number:number = 0;
    let optionAmount:number = 0;
  
      if(parseFloat(e.qty) <= 0.1 || e.qty == ''){
        this.itemsArr[i].qty = 0.1;
      }else if( e.qty === 1 || parseFloat(e.qty) === 1.0){
        this.itemsArr[i].qty = (e.qty - 0.9).toFixed(1);
  
      }else if (parseFloat(e.qty) < 1) {
        // Check if currentQty is close to 0.9 or 1 and set it to 0.1 if true
        this.itemsArr[i].qty = parseFloat(e.qty) - 1
         if(parseFloat(this.itemsArr[i].qty) <= 0.0 || this.itemsArr[i].qty <= 0){
          this.itemsArr[i].qty = 0.1
         }
      }else{
        this.itemsArr[i].qty = (e.qty - 1).toFixed(1);
      }

      if(this.itemsArr[i].optionAmount){
        optionAmount = parseFloat(this.itemsArr[i].optionAmount)
      }else{
        optionAmount = number
      }
        // let b = parseFloat(this.itemsArr[i].qty) * this.optionarray_amount;
        // let a = parseFloat(this.itemsArr[i].qty) * this.itemsArr[i].s_rate;
        // this.itemsArr[i].amount = a + b;
        this.itemsArr[i].amount = (optionAmount + this.itemsArr[i].s_rate) * parseFloat(this.itemsArr[i].qty)
      this.calculateAmt(); 
  }

  onChangeItemQtyOrRate(event:any, i:any) {
    let number:number = 0;
    let optionAmount:number = 0;

    // this.itemsArr[i].qty = parseFloat(this.itemsArr[i].qty)
    let rate = parseFloat(this.itemsArr[i].s_rate);
    // let optionAmount = parseFloat(this.itemsArr[i].optionAmount);
    if (rate < 1) { 
      this.itemsArr[i].s_rate = rate;
    }

    if(this.itemsArr[i].optionAmount){
      optionAmount = parseFloat(this.itemsArr[i].optionAmount)
    }else{
      optionAmount = number
    }
    
    this.itemsArr[i].amount = (this.itemsArr[i].s_rate + optionAmount) * (this.itemsArr[i].qty ? parseFloat(this.itemsArr[i].qty) : 0)
    this.calculateAmt();
  }

  updateCustomisation(item:any) {
    
    let updateItem: any;
    if (this.itemsArr.length > 0) {
    updateItem = this.itemsArr.filter((search_item:any) => {
      
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true
    
      // Check base item details
      const isBasicMatch = search_item.item_id === item.item_id &&
                           search_item.optionAmount === item.optionAmount &&
                           search_item.checkedVariantAmount === item.checkedVariantAmount;
    
      // Check childOptions if they exist
      if (item.optionsData && item.optionsData.length > 0 && item.optionsData[0].childOptions) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every((childOpt: any, index: number) => {
          return (
            search_item.optionsData &&
            search_item.optionsData.length > 0 &&
            search_item.optionsData[0].childOptions &&
            search_item.optionsData[0].childOptions[index] &&
            search_item.optionsData[0].childOptions[index].title === childOpt.title
          );
        });
      }
    
      // Check variant groups if they exist
      if (item.checkedVariant && item.checkedVariant.length > 0 && item.checkedVariant[0].variants) {
        isVariantsMatch = item.checkedVariant[0].variants.every((variant: any, index: number) => {
          return (
            search_item.checkedVariant &&
            search_item.checkedVariant.length > 0 &&
            search_item.checkedVariant[0].variants &&
            search_item.checkedVariant[0].variants[index] &&
            search_item.checkedVariant[0].variants[index].title === variant.title
          );
        });
      }
    
      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    })
  }
    const data = { item: item, status: "update" };
    this.openCustomization(data);
  }


  updateItemTitle(item: any) {
    let newTitle = item.item_code; // Start with the original item name

    // Check for any checked variants
    if (item.checkedVariant && item.checkedVariant.length > 0) {
      item.checkedVariant.forEach((variantGroup: any) => {
        if (variantGroup.ischecked || variantGroup.checked || variantGroup.wera_active) {
          newTitle += ` - ${variantGroup.title}`; // Append the parent variant group title if checked

          variantGroup.variants.forEach((variant: any) => {
            if (variant.ischecked || variant.checked || variantGroup.wera_active) {
              newTitle += ` - ${variant.title}`; // Append the variant title if checked
            }
          });
        }
      });
    }

    // Check for any checked options
    if (item.optionsData && item.optionsData.length > 0) {
      item.optionsData.forEach((option: any) => {
        if (option.ischecked || option.checked || option.wera_active) {
          newTitle += ` - ${option.title}`; // Append option title if checked
        }

        // Handle child options, if available and checked
        if (option.childOptions && option.childOptions.length > 0) {
          option.childOptions.forEach((childOption: any) => {
            if (childOption.ischecked || childOption.checked || childOption.wera_active) {
              newTitle += ` - ${childOption.title}`; // Append child option title if checked
            }
          });
        }
      });
    }

    return newTitle;
  }

}
