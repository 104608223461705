<form [formGroup]="form">

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel #panel hideToggle style="padding: 0px 5px">
        <mat-expansion-panel-header>
          <mat-panel-title> KOT </mat-panel-title>
          <mat-panel-description>
            Go for KOTs
          </mat-panel-description>
        </mat-expansion-panel-header>
  
  
        <app-kot-list [items]="KOT_data" [deleteIconName]="'Delete'" [incrementIconName]="'Add Circle'"
          [decrementIconName]="'Remove Circle'" [iconColor]="'#000'" [iconSize]="24"
          (decreaseQty)="decrease_qty($event.item, $event.index)" (increaseQty)="increase_qty($event.item, $event.index)"
          (deleteItem)="onDeleteItem1($event.index)" (cookingIns)="instrucctiondata($event.item, $event.n)"
          (customisation)="updateCustomisation($event.item)"
          (quantityChange)="onChangeItemQty($event.event, $event.i)">
        </app-kot-list>
  
        <mat-action-row>
          <button class="btn-primary" #btnGrp type="button" style="float: right" disabled *ngIf="waitforkot === true">
            <span class="spinner-border spinner-border-sm" role="status"></span>
            wait...
          </button>
          <button type="button" style="margin-right: 5px !important" class="btn-primary btn-sm" (click)="onOnlyAddKOT()"
            *ngIf="waitforkot === false">
            Save
          </button>
          <button type="button" class="btn-primary btn-sm" (click)="onAddKOT()" *ngIf="waitforkot === false">
            Save & Print
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="padding: 0px 5px">
        <mat-expansion-panel-header #opanel>
          <mat-panel-title> Orders </mat-panel-title>
          <mat-panel-description>
            Go for Orders
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="border scr-sec">
          <div *ngIf="!kotArr.length" class="alert alert-danger text-center mt-2 w-100">
            No Pending KOT found
          </div>
          <div>
            <section *ngIf="kotArr.length" class="select-all" style="height: 40px">
              <mat-checkbox class="form-margin" (change)="onSelectAllKOT($event.checked)" [checked]="select_all"
                name="select_all">Select All
              </mat-checkbox>
            </section>
            <div class="scroll-sec">
              <div class="list-group" *ngFor="let kot of kotArr">
                <div class="d-flex w-100 justify-content-between">
                  <section class="kot-sec w-100">
                    <mat-checkbox class="form-margin" (change)="onChangeKOT(kot, $event.checked)" [checked]="kot.added"
                      name="{{ kot.transaction_id }}">
                    </mat-checkbox>
                    <span class="table-font-arial pl-1 kot-header" data-toggle="collapse"
                      attr.data-target="#Item{{ kot.transaction_id }}">KOT#:{{ kot.trans_no }}
                      <span class="pull-right table-font-arial" style="padding-right: 10px"><i
                          class="fa fa-{{ currency }}"></i><strong>{{
                          kot.net_amount | number : "1.2-2"
                          }}</strong></span>
                    </span>
                    <span><button type="button" class="btn btn-sm edit-btn" (click)="onEditKot(kot.transaction_id)">
                        <mat-icon>edit</mat-icon>
                      </button></span>
                  </section>
                </div>
                <div *ngFor="let item of kot.items_details" class="pl-2 collapse" attr.id="Item{{ kot.transaction_id }}">
                  <span>{{ convertFloat(item.qty) }} x
                    {{ item.item_name }}</span>
                  <span class="pull-right table-font-arial"><i class="fa fa-{{ currency }}"></i>{{ item.amount | number :
                    "1.2-2" }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
  
      <mat-expansion-panel #panel3 hideToggle style="padding: 0px 5px">
        <mat-expansion-panel-header>
          <mat-panel-title> Invoice </mat-panel-title>
          <mat-panel-description>
            Go for Invoice
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row itm-sec">
          <div class="col-md-12 border mb-1">
            <div class="row">
              <div class="col-md-12 pt-1 customer">
                <div *ngIf="tableInfoDineIn">
                  <label class="font-weight-bold col-sm-6 w-100 p-0">Table
                    <span style="float: right; margin-right: 30px">:</span>
                  </label>
                  <label class="col-form-label">{{
                    tableInfoDineIn.tableName
                    }}</label>
                </div>
              </div>
              <div class="col-md-12 pt-1 customer">
                <label class="col-sm-6 w-100 p-0"><strong>Trans no.
                    <span style="float: right; margin-right: 30px">:</span></strong>
                </label>
                <label class=""> Auto</label>
              </div>
              <div class="col-md-12 pt-1 customer">
                <div [formGroup]="contactForm">
                  <div class="form-group w-100 row">
                    <label for="contact_no" class="col-sm-6 font-weight-bold pull-right">
                      Contact No
                      <span style="float: right">:</span>
                    </label>
                    <div class="col-sm-6 w-100 search-button">
                      <input type="text" placeholder="Enter Number" aria-label="Search" class="form-control form-control-xs"
                        [(ngModel)]="inputValue" id="contact_no" formControlName="contact_no" [matAutocomplete]="numberAuto"
                        (input)="onInput($event)" (ngModelChange)="handleInputChange($event)"
                        (keyup.enter)="handleEnterKey($event)" maxlength="10" pattern="[0-9]*" />
                      <mat-autocomplete #numberAuto="matAutocomplete" autoActiveFirstOption>
                        <mat-option *ngFor="let i of listNumber" (click)="searchByPhone(i)" [value]="i">
                          <span class="item-font">{{ i }}</span>
                        </mat-option>
                      </mat-autocomplete>
        
                    </div>
                    
                  </div>
                </div>
  
                <div class="form-group w-100 row">
                  <label for="customer_name" class="col-sm-6 font-weight-bold pull-right">
                    Customer Name<span style="float: right">:</span>
                  </label>
                  <div class="col-sm-6 w-100">
                    <input type="text" class="form-control form-control-xs" id="customer_name"
                      formControlName="customer_name" />
                  </div>
                </div>
  
                <div class="form-group w-100 row">
                  <label for="email" class="col-sm-6 font-weight-bold pull-right">
                    Email
                    <span style="float: right">:</span>
                  </label>
                  <div class="col-sm-6 w-100">
                    <input type="text" class="form-control form-control-xs" id="email" formControlName="email" />
                  </div>
                </div>
                <div class="form-group w-100 row">
                  <label for="email" class="col-sm-6 font-weight-bold pull-right">
                    Address
                    <span style="float: right">:</span>
                  </label>
                  <div class="col-sm-6 w-100">
                    <textarea style="height: 50px !important" class="form-control form-control-xs" id="address"
                      formControlName="address" rows="3"></textarea>
                  </div>
                </div>
              </div>
  
              <div class="col-md-12 pt-1">
                <div class="form-group w-100 row">
                  <label for="gstin" class="col-sm-6 font-weight-bold pull-right">GSTIN <span
                      style="float: right">:</span></label>
                  <div class="col-sm-6 w-100">
                    <input type="text" class="form-control form-control-xs" id="gstin" style="
                        border: none;
                        border-bottom: 1px solid;
                        border-radius: 0;
                      " formControlName="gstin" />
                  </div>
                </div>
                <div class="form-group w-100 row">
                  <label for="date" class="col-sm-6 font-weight-bold pull-right">Date <span
                      style="float: right">:</span></label>
                  <div class="col-sm-6 w-100" style="display: flex; height: 20px">
                    <input style="width: 157px; border: none" matInput [matDatepicker]="trans_date"
                      formControlName="trans_date" required />
                    <mat-datepicker-toggle matSuffix [for]="trans_date" style="margin-top: -12px"></mat-datepicker-toggle>
                    <mat-datepicker #trans_date></mat-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="scroll-sec-itm">
          <table class="table item-tbl">
            <thead>
              <tr>
                <th>#</th>
                <th class="itm-name">Item Name</th>
                <th class="text-center">Qty</th>
                <th class="right">Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="itm-tbody">
              <tr *ngFor="let item of itemsArr; let i = index">
                <td data-title="Sl" *ngIf="item.addons_parent_id === null">
                  {{ i + 1 }}
                </td>
                <td data-title="Name" class="table-font-arial itm-name" *ngIf="item.addons_parent_id === null">
                  {{ item.item_name }}
                </td>
                <td data-title="Qty" class="table-font-arial" *ngIf="item.addons_parent_id === null">
                  <input type="text" class="form-control form-control-xs text-center" value="{{ convertFloat(item.qty) }}"
                    (keyup)="onChangeItemQty($event, i)" />
                </td>
                <td data-title="Amount" class="table-font-arial right" *ngIf="item.addons_parent_id === null">
                  {{ item.amount | number : "1.2-2" }}
                </td>
                <td data-title="Actions" class="action-itm" *ngIf="item.addons_parent_id === null">
                  <button
                      type="button"
                      class="btn-sm del-btn btn"
                      (click)="onDeleteItem(i)"
                      style="margin-left: 7px; margin-top: 1px"
                    >
                      <mat-icon>delete_outline</mat-icon>
                  </button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text">Amount &nbsp;<i class="fa fa-{{ currency }}"></i></span>
            </div>
            <input type="number" class="form-control" formControlName="total_amount" readonly />
          </div>
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Discount %</div>
            </div>
            <input type="number" formControlName="dis_per" class="form-control" (input)="onDiscountPer($event)"
              (focus)="onDiscountFocus(true)" (blur)="onDiscountFocus(false)" min="0" />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="discount_amt" class="form-control" (input)="onDiscountAmt($event)" />
          </div>
  
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">
                Tax &nbsp;
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="total_tax" class="form-control" readonly />
          </div>
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Other1</div>
            </div>
            <input formControlName="other1_label" class="form-control" />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="other1_amt" class="form-control" (input)="onOtherPrice()" min="0" />
          </div>
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">Other2</div>
            </div>
            <input formControlName="other2_label" class="form-control" />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="other2_amt" class="form-control" (input)="onOtherPrice()" min="0" />
          </div>
  
  
          <div class="input-group mb-1 input-group-sm" *ngIf="isServiceCharge">
            <div class="input-group-prepend">
              <div class="input-group-text">Service Charge %</div>
            </div>
            <input type="number" formControlName="service_charge_per" class="form-control" (input)="onServicePer($event)"
              (focus)="onServiceFocus(true)" (blur)="onServiceFocus(false)" min="0" />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="service_charge" class="form-control" (input)="onDiscountAmt($event)"
              readonly />
          </div>
  
  
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">
                Total &nbsp;
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="gross_amount" class="form-control" readonly />
          </div>
          <div class="input-group mb-1 input-group-sm">
            <div class="input-group-prepend">
              <div class="input-group-text">
                RoundOff &nbsp;
                <i class="fa fa-{{ currency }}"></i>
              </div>
            </div>
            <input type="number" formControlName="roundoff_amt" class="form-control" readonly />
          </div>
          <table class="table table-borderless net-amt-table">
            <td>
              <label class="big form-bold-label">Net Amount:</label>
            </td>
            <td>
              <label class="big form-bold-label">
                <i class="fa fa-{{ currency }}"></i>{{ form.value.net_amount | number : "1.2-2" }}</label>
            </td>
          </table>
        </div>
  
  
        <mat-action-row class="col-md-12">
          <!-- First row for No cost checkbox and OTP input -->
          <ng-container *ngIf="itemsArr.length > 0" class="col-md-12">
            <div class="row mt-3">
              <div
                class="input-group-text col-md-5 d-flex align-items-center"
                style="margin-right: 0px; height: 30px"
              >
                <label for="checkbox2" style="margin-right: 8px"
                  >No cost</label
                >
                <input
                  type="checkbox"
                  id="checkbox2"
                  style="transform: scale(1.5); margin-left: 8px"
                  (change)="onCheckboxChange()"
                />
              </div>
              <div
                [formGroup]="validationForm"
                (ngSubmit)="onSubmit()"
                *ngIf="showValidationInput"
                class="col-md-5 mt-1"
              >
                <div *ngIf="!formSubmitted" style="width: 90px">
                  <input
                    formControlName="validationCode"
                    class="form-control"
                    placeholder="OTP"
                    style="width: 100%; height: 30px"
                  />
                </div>
              </div>
            </div>
          </ng-container>

          <!-- Second row for buttons -->

          <form
            class="col-md-8"
            style="padding: 10px; margin-left: 20px"
            *ngIf="itemsArr.length > 0"
            [formGroup]="validationForm"
            (ngSubmit)="onSubmit()"
          >
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-end flex-wrap"
              >
                <button
                  class="btn btn-primary btn-sm"
                  type="button"
                  (click)="SaveAndPrint()"
                  *ngIf="
                    itemsArr.length > 0 && waitforinvoice === false
                  "
                  [disabled]="
                    showValidationInput &&
                    (!validationForm.valid || !otpVerified)
                  "
                  style="
                    font-size: 10px;
                    width: 30%;
                    margin-right: 3px;
                  "
                >
                  Save & Print
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  type="button"
                  (click)="SaveAndEbill()"
                  *ngIf="
                    itemsArr.length > 0 && waitforinvoice === false
                  "
                  
                  style="
                    font-size: 10px;
                    width: 30%;
                    margin-right: 3px;
                  "
                >
                  Save & eBill
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  type="button"
                  (click)="onSubmit()"
                  *ngIf="waitforinvoice === false"
                  [disabled]="
                    showValidationInput &&
                    (!validationForm.valid || !otpVerified)
                  "
                  style="
                    font-size: 10px;
                    width: 30%;
                    margin-right: 3px;
                  "
                >
                  Save
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  type="button"
                  disabled
                  *ngIf="waitforinvoice === true"
                  style="width: 30%"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  wait...
                </button>
              </div>
            </div>
          </form>
        </mat-action-row>
        <div *ngIf="
            form.controls['dis_per'].value > 0 &&
            showValidationDiscount
          ">
          <div style="color: red">
            Please select either Discount or No Cost.
          </div>
        </div>
        <div *ngIf="otpVerified" style="color: green">
          OTP is verified
        </div>
        <div *ngIf="otpInvalid" style="color: red">Invalid OTP</div>
      </mat-expansion-panel>
      <mat-expansion-panel #panel4 hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> Due Invoice </mat-panel-title>
          <mat-panel-description>
            Go for Due Invoice
          </mat-panel-description>
        </mat-expansion-panel-header>
  

        <table mat-table [dataSource]="dueInvoiceObj" matSort multiTemplateDataRows>
          <!-- Customer Name Column -->
          <ng-container matColumnDef="customer_name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [ngClass]="'customWidthClass'"
              mat-sort-header
            >
              Customer
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-label="Customer"
              [ngClass]="'customWidthClass'"
            >
              {{ row.customer_name }}
            </td>
          </ng-container>
        
          <!-- Invoice Column -->
          <ng-container matColumnDef="display_trans_no">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Invoice
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-label="Invoice"
              (click)="receiptDialog(row)"
              style="color: blue; cursor: pointer"
            >
              {{ row.display_trans_no }}
            </td>
          </ng-container>
        
          <!-- Amount Column -->
          <ng-container matColumnDef="net_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Amount
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-label="Amount"
              style="text-align: right"
            >
              {{ row.net_amount | number : "1.2-2" }}
            </td>
          </ng-container>
        
          <!-- Advance Column -->
          <ng-container matColumnDef="advance">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" data-label="advance">
              <button
                mat-icon-button
                (click)="receiptDialog(row)"
                style="float: right; color: blue"
              >
                <mat-icon>open_in_new</mat-icon>
              </button>
            </td>
          </ng-container>
        
          <!-- Input Row -->
          <ng-container matColumnDef="input_row">
            <td mat-cell *matCellDef="let row" colspan="4">
              <div class="button-container">
                <button
                class="payment-button cash-button"
                style="color: white;"
                  mat-raised-button
                *ngIf="isLoading === true">
                <span class="spinner-border spinner-border-sm" role="status"></span>
                wait...
                </button>
    
                <button
                *ngIf="isLoading === false"
                style="color: white;"
                  mat-raised-button
                  (click)="onReceipt(row, 'cash')"
                  class="payment-button cash-button"
                >
                  <mat-icon>attach_money</mat-icon> Cash
                </button>
    
    
                <button
                class="payment-button card-button"
                style="color: white;"
                  mat-raised-button
                *ngIf="isLoading === true">
                <span class="spinner-border spinner-border-sm" role="status"></span>
                wait...
                </button>
    
                <button
                *ngIf="isLoading === false"
                style="color: white;"
                  mat-raised-button
                  (click)="onReceipt(row, 'card')"
                  class="payment-button card-button"
                >
                  <mat-icon>credit_card</mat-icon> Card
                </button>
    
    
                <button
                class="payment-button upi-button"
                style="color: white;"
                  mat-raised-button
                *ngIf="isLoading === true">
                <span class="spinner-border spinner-border-sm" role="status"></span>
                wait...
                </button>
    
                <button
                *ngIf="isLoading === false"
                style="color: white;"
                  mat-raised-button
                  (click)="onReceipt(row, 'upi')"
                  class="payment-button upi-button"
                >
                  <mat-icon>qr_code_scanner</mat-icon> UPI
                </button>
              </div>
            </td>
          </ng-container>
          


        
          <!-- Header Row -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        
          <!-- Data Row -->
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ 'table-danger': row.active == false }"
          ></tr>
        
          <!-- Detail Input Row -->
          <tr
            mat-row
            *matRowDef="let row; columns: ['input_row']"
            class="detail-row"
          ></tr>
        </table>


        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
  
        <mat-action-row>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </form>