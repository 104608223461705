<form [formGroup]="form">

    <div class="col">
      <div class="">
        <div class="row">
          <div class="col-md-12 pt-1 customer">
            <div *ngIf="tableObj">
              <label class="font-weight-bold col-sm-6 w-100 p-0">Table
                <span style="float: right; margin-right: 30px">:</span></label>
              <label class="col-form-label">{{
                tableObj.name
                }}</label>
            </div>
          </div>
          <div class="col-md-12 pt-1 customer">
            <label class="col-sm-6 w-100 p-0"><strong>Trans no.
                <span style="float: right; margin-right: 30px">:</span></strong>
            </label>
            <label class=""> Auto</label>
          </div>
          <div class="col-md-12 pt-1 customer">
            <div class="form-group w-100 row">
              <label for="contact_no" class="col-sm-6 font-weight-bold pull-right">
                Contact No
                <span style="float: right">:</span>
              </label>
              <div class="col-sm-6 w-100 search-button">
                <input type="text" placeholder="Enter Number" aria-label="Search" class="form-control form-control-xs"
                  [(ngModel)]="inputValue" id="contact_no" formControlName="contact_no" [matAutocomplete]="numberAuto"
                  (input)="onInput($event)" (ngModelChange)="handleInputChange($event)"
                  (keyup.enter)="handleEnterKey($event)" maxlength="10" pattern="[0-9]*" />
                <mat-autocomplete #numberAuto="matAutocomplete" autoActiveFirstOption>
                  <mat-option *ngFor="let i of listNumber" (click)="searchByPhone(i)" [value]="i">
                    <span class="item-font">{{ i }}</span>
                  </mat-option>
                </mat-autocomplete>
  
              </div>
            </div>
  
            <div class="form-group w-100 row">
              <label for="customer_name" class="col-sm-6 font-weight-bold pull-right">Customer Name<span
                  style="float: right">:</span></label>
              <div class="col-sm-6 w-100">
                <input type="text" class="form-control form-control-xs" id="customer_name"
                  formControlName="customer_name" />
              </div>
            </div>
            <div class="form-group w-100 row">
              <label for="email" class="col-sm-6 font-weight-bold pull-right">Email <span
                  style="float: right">:</span></label>
              <div class="col-sm-6 w-100">
                <input type="text" class="form-control form-control-xs" id="email" formControlName="email" />
              </div>
            </div>
            <div class="form-group w-100 row">
              <label for="email" class="col-sm-6 font-weight-bold pull-right">
                Address
                <span style="float: right">:</span>
              </label>
              <div class="col-sm-6 w-100">
                <textarea style="height: 50px !important" class="form-control form-control-xs" id="address"
                  formControlName="address" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-1">
            <div class="form-group w-100 row">
              <label for="gstin" class="col-sm-6 font-weight-bold pull-right">GSTIN <span
                  style="float: right">:</span></label>
              <div class="col-sm-6 w-100">
                <input type="text" class="form-control form-control-xs" id="gstin" formControlName="gstin" style="
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid;
                  " />
              </div>
            </div>
  
          </div>
        </div>
      </div>
  
      <div class="scroll-sec-itm">
  
        <app-kot-list [items]="itemsArr" [deleteIconName]="'Delete'" [incrementIconName]="'Add Circle'"
        [decrementIconName]="'Remove Circle'" [iconColor]="'#000'" [iconSize]="24"
        (decreaseQty)="decrease_qty($event.item, $event.index)" (increaseQty)="increase_qty($event.item, $event.index)"
        (deleteItem)="onDeleteItem($event.index)" (cookingIns)="instrucctiondata($event.item, $event.n)"
        (customisation)="updateCustomisation($event.item)"
        (quantityChange)="onChangeItemQty($event.event, $event.i)">
      </app-kot-list>
      </div>
      <mat-expansion-panel #panel1 hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="input-group mb-1 input-group-sm">
              <div class="input-group-prepend" *ngFor="let i of disposallist">
                <span class="input-group-text" (click)="showdisposalitems(i.category_id)">
                  {{ i.category_name }}</span>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend" style="list-style-type: none; padding: 2px" *ngFor="let i of disposalItemlist">
            <div>
              <button class="input-group-text" (click)="addItemsone(i)" style="font-size: 0.875rem">
                {{ i.item_name }}
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Amount &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="total_amount" readonly />
        </div>
  
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Discount %</div>
          </div>
          <input type="number" formControlName="dis_per" class="form-control" (input)="onDiscountPer($event)"
            (focus)="onDiscountFocus(true)" (blur)="onDiscountFocus(false)" min="0" />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="discount_amt" class="form-control" (input)="onDiscountAmt($event)" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Tax %</div>
          </div>
          <input type="number" value="{{ form.value.taxslab }}" class="form-control" readonly />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="total_tax" class="form-control" readonly />
        </div>
  
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Other1</div>
          </div>
          <input formControlName="other1_label" class="form-control" />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="other1_amt" class="form-control" (keyup)="onOtherPrice()" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">Other2</div>
          </div>
          <input formControlName="other2_label" class="form-control" />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="other2_amt" class="form-control" (keyup)="onOtherPrice()" />
        </div>
  
        <div class="input-group mb-1 input-group-sm" *ngIf="isServiceCharge">
          <div class="input-group-prepend">
            <div class="input-group-text">Service Charge %</div>
          </div>
          <input type="number" formControlName="service_charge_per" class="form-control" (input)="onServicePer($event)"
            (focus)="onServiceFocus(true)" (blur)="onServiceFocus(false)" min="0" />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="service_charge" class="form-control" (input)="onDiscountAmt($event)"
            readonly />
        </div>
  
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">
              Total &nbsp;
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="gross_amount" class="form-control" readonly />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text">
              RoundOff &nbsp;
              <i class="fa fa-{{ currency }}"></i>
            </div>
          </div>
          <input type="number" formControlName="roundoff_amt" class="form-control" readonly />
        </div>
        <table class="table table-borderless net-amt-table">
          <td>
            <label class="big form-bold-label">Net Amount:</label>
          </td>
          <td>
            <label class="big form-bold-label">
              <i class="fa fa-{{ currency }}"></i>{{ form.value.net_amount | number : "1.2-2" }}</label>
          </td>
        </table>
      </div>
      <div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Cash Receive &nbsp;<i class="fa fa-{{ currency }}00"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="cash_receive" (keyup)="onCashReceive()" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Cash &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="cash" (keyup)="onCashOrCard()" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Card &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="card" (keyup)="onCashOrCard()" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">UPI &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="upi" (keyup)="onCashOrCard()" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Card Ref.No</span>
          </div>
          <input type="text" class="form-control" formControlName="card_ref_no" />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Total &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="advance" readonly />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Balance &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" formControlName="balance" readonly />
        </div>
        <div class="input-group mb-1 input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Refund &nbsp;<i class="fa fa-{{ currency }}"></i></span>
          </div>
          <input type="number" class="form-control" value="{{ refund_amt }}" readonly />
        </div>
      </div>
  
      <mat-action-row>
        <div class="row">
  
          <div class="" [ngClass]="{
              takeAwaySubmitButton:
                itemsArr.length > 0 && isEbillingEnable == 'true'
            }">
            <button class="btn-primary" #btnGrp type="button" style="float: right" disabled
              *ngIf="waitforTakeaway === true">
              <span class="spinner-border spinner-border-sm" role="status"></span>
              wait...
            </button>
  
            <button type="button" #btnGrp class="btn-primary" (click)="createTakeAwayOrder()"
              *ngIf="itemsArr.length > 0 && waitforTakeaway === false">
              Kot & Invoice
            </button>
          </div>
        </div>
      </mat-action-row>
    </div>
  
    <hr />
    <h5>Pending Invoice</h5>

    <table mat-table [dataSource]="dueInvoiceObj" matSort multiTemplateDataRows>
      <!-- Customer Name Column -->
      <ng-container matColumnDef="customer_name">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="'customWidthClass'"
          mat-sort-header
        >
          Customer
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          data-label="Customer"
          [ngClass]="'customWidthClass'"
        >
          {{ row.customer_name }}
        </td>
      </ng-container>
    
      <!-- Invoice Column -->
      <ng-container matColumnDef="display_trans_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Invoice
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          data-label="Invoice"
          (click)="receiptDialog(row)"
          style="color: blue; cursor: pointer"
        >
          {{ row.display_trans_no }}
        </td>
      </ng-container>
    
      <!-- Amount Column -->
      <ng-container matColumnDef="net_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Amount
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          data-label="Amount"
          style="text-align: right"
        >
          {{ row.net_amount | number : "1.2-2" }}
        </td>
      </ng-container>
    
      <!-- Advance Column -->
      <ng-container matColumnDef="advance">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" data-label="advance">
          <button
            mat-icon-button
            (click)="receiptDialog(row)"
            style="float: right; color: blue"
          >
            <mat-icon>open_in_new</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <!-- Input Row -->
      <ng-container matColumnDef="input_row">
        <td mat-cell *matCellDef="let row" colspan="4">
          <div class="button-container">
            <button
            style="color: white;"
              mat-raised-button
              (click)="onReceipt(row, 'cash')"
              class="payment-button cash-button"
            >
              <mat-icon>attach_money</mat-icon> Cash
            </button>
            <button
            style="color: white;"
              mat-raised-button
              (click)="onReceipt(row, 'card')"
              class="payment-button card-button"
            >
              <mat-icon>credit_card</mat-icon> Card
            </button>
            <button
            style="color: white;"
              mat-raised-button
              (click)="onReceipt(row, 'upi')"
              class="payment-button upi-button"
            >
              <mat-icon>qr_code_scanner</mat-icon> UPI
            </button>
          </div>
        </td>
      </ng-container>
      


    
      <!-- Header Row -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    
      <!-- Data Row -->
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'table-danger': row.active == false }"
      ></tr>
    
      <!-- Detail Input Row -->
      <tr
        mat-row
        *matRowDef="let row; columns: ['input_row']"
        class="detail-row"
      ></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
  </form>