import { Component, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../stories-module/icons/icons.component";

@Component({
  selector: "app-kot-list",
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: `./kot-list.component.html`,
  styleUrls: ["./kot-list.component.css"],
})
export class KotListComponent {
  // Input for the list of items, the structure follows the real data
  @Input() items: {
    item_id: number;
    category_id: number;
    category_name: string;
    item_code: string;
    item_name: string;
    s_rate: number;
    imgurl: string;
    qty: number;
    amount: number;
    instruction: string;
    checkedVariant: any[]; // Use an array type here
    optionsData: any[]; // Use an array type here
  }[] = [];

  // Icon Inputs
  @Input() deleteIconName: string = "Delete"; // Icon for delete button
  @Input() incrementIconName: string = "Add Circle"; // Icon for increment button
  @Input() decrementIconName: string = "Remove Circle"; // Icon for decrement button
  @Input() iconColor: string = "#000";
  @Input() iconSize: number = 24;
  showCookingInstructions: boolean = true;

  // Headers for item name, quantity, and amount
  itemNameHeader: string = "Item Name";
  qtyHeader: string = "Qty";
  amountHeader: string = "Amount";
  // Outputs to emit changes
  @Output() remove = new EventEmitter<number>();
  @Input() item: any; // The item being passed as an input
  @Input() i: any; // Pass `i` as an input

  @Output() increaseQty = new EventEmitter<{ item: any; index: number }>();
  @Output() decreaseQty = new EventEmitter<{ item: any; index: number }>();
  @Output() deleteItem = new EventEmitter<{ index: number }>();
  @Output() cookingIns = new EventEmitter<{ item: any; n: any }>();
  @Output() customisation = new EventEmitter<{ item: any }>();
  @Output() quantityChange = new EventEmitter<{ event: any; i: any }>();
  
  decrease_qty(item: any, index: number): void {
    this.decreaseQty.emit({ item, index });
  }

  // Function to change the item quantity (triggered by input change)
  onChangeItemQty(event: Event, i: any): void {
    this.quantityChange.emit({ event, i }); // Emit event and `i`
  }

  // Function to convert quantity to a fixed float value
  convertFloat(value: any): string {
    return parseFloat(value).toFixed(2); // Return the value with two decimal points
  }

  // Function to increase quantity
  increase_qty(item: any, index: number): void {
    console.log("reched--");
    
    this.increaseQty.emit({ item, index });
  }

  // Function to delete an item from the list
  onDeleteItem1(index: number): void {
    this.deleteItem.emit({ index });
  }

  // Function to handle cooking instruction data input
  instrucctiondata(item: any, n: any): void {
    this.cookingIns.emit({ item, n });
  }

  // Function to handle customisation update (optional feature)
  updateCustomisation(item: any): void {
    this.customisation.emit({ item });
  }
}
