import { Component, Input, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { KotListComponent } from '../../../../stories/stories-module/kot-list/kot-list.component';
import { SharedModule } from '../../../common.module';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CacheServiceService } from '../../../cache-service.service';
import { RstDashboardService } from '../../../_services/rst-dashboard.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CalculationsService } from '../../../_services/calculations.service';
import { Notyf } from 'notyf';
import { RstKotComponent } from '../../rst-kot/rst-kot.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReceiptDialogComponent } from '../../receipt-dialog/receipt-dialog.component';
import { ItemsDialogComponent } from '../../items-dialog/items-dialog.component';
import { Subject, takeUntil } from 'rxjs';
// import { EventEmitter } from 'stream';
const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});
@Component({
  selector: 'app-dine-in',
  standalone: true,
  imports: [
    SharedModule,
    KotListComponent
  ],
  templateUrl: './dine-in.component.html',
  styleUrl: './dine-in.component.css'
})
export class DineInComponent {
  categoryObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  @Input() table_id!: number;
  @Input() tableInfo!: any;
  waitforinvoice: boolean = false;
  waitforkot: boolean = false;
  waitforTakeaway: boolean = false;
  private destroy$ = new Subject<void>();
  kotArr: any = [];
  KOT_data: any = [];
  errorMessage: string | null = null;
  tableInfoDineIn: any = [];
  itemsArr: any = [];
  contactNo: any;
  model: any = {};
  select_all: boolean = true;
  currency: any;
  tableObj: any;
  contactForm: FormGroup;
  inputValue: string = "";
  listNumber: any[] = [];
  isServiceCharge: boolean = false;
  validationForm: FormGroup;
  showValidationInput: boolean = false;
  formSubmitted: boolean = false;
  otpVerified: boolean = false;
  showValidationDiscount: boolean = false;
  otpInvalid: boolean = false;
  non_receivable: boolean = false;
  getItems: boolean = false;
  getCategory: boolean = false;
  getSubCategory: boolean = false;
  getDisposalitems: boolean = false;
  getDisposalCategory: boolean = false;
  dueInvoiceObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = [
    "customer_name",
    "display_trans_no",
    "net_amount",
    "advance",
  ];
  // tableInfo: any;
  mapArr: any;
  dueInvoice: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isLocal: boolean = false;
  disposalItemData: any;
  disposallist: any;
  ebillData: any = [];
  catArr: any;
  table_name: any;
  allowChangeRate: boolean = false;
  soIdArr: any = [];
  @ViewChild("yourExpansionPanel") yourExpansionPanel!: MatExpansionPanel;
  @ViewChild("panel") panel!: MatExpansionPanel;
  @ViewChild("panel3") panel3!: MatExpansionPanel;
  @ViewChild("panel4") panel4!: MatExpansionPanel;
  // @Output() onKotAdd: EventEmitter<any> = new EventEmitter<any>();
  model_kot: any = { trans_date: new Date(), net_amount: 0 };
  transDataObj: any = { items_details: [] };
  invoice_no: any;
  currentKOTData: any[] = [];
  isAutoFocus: any;
  isEditMode: boolean = false;
  editContactId: number | null = null;
  optionarray_amount: any;
  options_array: any;
  checkedVariantAmount: any;
  checkedVariant: any;
  isTransactionGSTslab: boolean = false;
  serviceChargeValue: any;
  serviceChargeAmount : any;
  gstSlab: any;
  data: any;
  dine_in: boolean = true;
  take_away: boolean = true
  order_tab: any = "Dine-In";




  constructor(
    private apiService: RstDashboardService,
    public dialog: MatDialog,
    private cacheService: CacheServiceService,
    private fb: FormBuilder,
    private calculationService: CalculationsService
  ) {

    this.contactForm = this.fb.group({
      contact_no: ["", [Validators.pattern(/^(\d{10})?$/)]], // 10 digits or empty
    });

    this.validationForm = this.fb.group({
      validationCode: new FormControl(""),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if 'table_id' property has changed
    if (changes['table_id']) {
    }

    // Check if 'tableInfo' has changed and has a valid (non-null/undefined) value
    if (changes['tableInfo'] && changes['tableInfo'].currentValue) {

      // Assign the new value of tableInfo to tableInfoDineIn
      this.tableInfoDineIn = this.tableInfo;
      // Call load() after updating tableInfoDineIn
      this.load()
    }
  }

  /**
   * Resets all data structures and cart state for the dine-in component
   * 
   * This method performs the following operations:
   * - Clears all arrays (items, sale order IDs, KOT related arrays)
   * - Resets table information arrays
   * - Clears the dine-in cart through the API service
   * - Triggers a request reset
   * 
   * @returns {void}
   */
  resetData(): void {
    this.itemsArr = [];          // Clears the items array
    this.soIdArr = [];           // Clears the sale order IDs array
    this.kotArr = [];            // Clears the KOT array
    this.KOT_data = [];          // Clears the KOT data array
    this.tableInfoDineIn = [];   // Clears the dine-in table information
    this.tableInfo = [];         // Clears the general table information
    this.apiService.addItemToDineInCart({}); // Resets the dine-in cart

    this.requestReset();         // Triggers additional reset operations
  }

  @Output() resetRequested = new EventEmitter<any>();

  requestReset() {
    this.resetRequested.emit({ reason: 'User clicked reset button' }); // Emit event with optional data
  }

  load() {

    this.initializeFormGroup()

    this.dineInData();

    this.findAccSetting()
  }

  ngOnInit() {
    this.apiService.addItemToDineInCart({})
    this.apiService.getItemToDineInCart()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {

        if (result?.item_id) {
          this.addItemCardClicked(result);
        }

      })

    this.validationForm
      .get("validationCode")
      ?.valueChanges.subscribe((value: string) => {
        if (value && value.length === 5) {
          this.otpVerified = false;
          this.otpInvalid = false;
        } else if (value && value.length === 6) {
          this.verifyEmailOtp(value);
        }
      });

  }

// Lifecycle hook for cleanup when the component is destroyed
ngOnDestroy() {
  this.destroy$.next(); // Emits a signal to terminate observables
  this.destroy$.complete(); // Completes the observable
}

// Method to verify the email OTP
verifyEmailOtp(validationCode: string) {
  // Prepares the request body with the validation code
  const requestBody = { code: validationCode };

  // Calls the API to verify the OTP
  this.apiService.verifyemailotp(requestBody).subscribe(
    (result: any) => {
      // If the verification is successful
      if (result && result.success) {
        this.otpVerified = true; // Marks OTP as verified
        this.otpInvalid = false; // Resets invalid flag
        this.formSubmitted = true; // Marks form as submitted
        notyf.success("OTP verified successfully"); // Displays success notification
      }

      // If the verification fails
      if (result && !result.success) {
        this.otpInvalid = true; // Marks OTP as invalid
        this.otpVerified = false; // Resets verified flag
        notyf.error(result); // Displays error notification
      }
    },
    (error) => {
      // Logs API error for debugging
      console.error("API Error:", error);
    }
  );
}


  // Function to process and set various account settings from tableInfo.accountMapList
  findAccSetting() {
    // Store account mapping list in mapArr for easier access
    this.mapArr = this.tableInfo.accountMapList;

    // Check and set Transaction GST slab setting
    const transactionGstSlab = this.mapArr.find((e: any) => e.key === "Transaction_gstslab");
    this.isTransactionGSTslab = transactionGstSlab?.checkbox_value || false;

    // If Transaction GST slab is enabled, set the tax slab value in the form
    if (this.isTransactionGSTslab) {
      const gstSlabEntry = this.mapArr.find((i: any) => i.key === "gstslab_value");
      if (gstSlabEntry) {
        this.form.get("taxslab")?.setValue(gstSlabEntry.template_value);
        this.gstSlab = gstSlabEntry?.template_value;
      }
    }

    // Check and set Service Charge setting
    const serviceChargeEntry = this.mapArr.find((e: any) => e.key === "service_charge");
    this.isServiceCharge = serviceChargeEntry?.checkbox_value || false;

    // If Service Charge is enabled, process service charge settings
    if (this.isServiceCharge) {
      const serviceChargeValueEntry = this.mapArr.find(
        (i: any) => i.key === "service_charge_value"
      );

      if (serviceChargeValueEntry) {
        this.serviceChargeValue = serviceChargeValueEntry.text_value;
        this.form.get("service_charge_per")?.setValue(serviceChargeValueEntry.text_value);
        this.onServicePerOnLoad(this.serviceChargeValue)
      }
    }

    // Check and set Auto Focus setting
    const autoFocusEntry = this.mapArr.find(
      (i: any) => i.key === "Enable_Auto_Focus"
    );
    this.isAutoFocus = autoFocusEntry?.checkbox_value || false;
  }



  /**
   * Handles the click event when an item card is selected
   * @param i The item object containing details like item_id, options, and variant groups
   */
  addItemCardClicked(i: any) {

    // Return early if the item doesn't have an ID
    if (!i.item_id) return;

    // Prepare data object with item details and context
    const data = { item: i, status: "add", source: "Dine-In" };

    // Check if the item already exists in KOT_data by matching item_id and item_name
    const index = this.KOT_data.findIndex(
      (itm: any) => itm.item_id === i.item_id && itm.item_name == i.item_name
    );

    // Handle item addition based on whether it exists and has customization options
    if (index !== -1) {
      // Item exists in KOT_data
      // Check if item has options or variant groups
      (i.options && i.options.length > 0) || (i.variant_groups && i.variant_groups.length) > 0
        ? this.openCustomization(data)    // Open customization dialog if options exist
        : this.addItemsone(i);           // Add item directly if no options
    } else {
      // Item doesn't exist in KOT_data
      // Similar check for options and variant groups
      (i.options && i.options.length) > 0 || (i.variant_groups && i.variant_groups.length) > 0
        ? this.openCustomization(data)    // Open customization dialog if options exist
        : this.addItemsone(i);           // Add item directly if no options
    }
  }


  /**
   * Opens a dialog for item customization
   * @param e Object containing item details and context information
   */
  openCustomization(e: any) {
    // Open the ItemsDialogComponent in a modal dialog
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,          // Pass the item data to the dialog
        width: "600px",   // Set dialog width
      }
    );

    // Handle the dialog close event
    dialogRef.afterClosed().subscribe((result) => {
      // Process the result only if it exists and comes from "Dine-In" source

        const item = result.item;

        // Handle different operations based on status
        if (result.status === "add") {
          // Store customization selections
          this.optionarray_amount = item.optionAmount;      // Selected options total amount
          this.options_array = item.optionsData;            // Selected options data
          this.checkedVariantAmount = item.checkedVariantAmount;  // Selected variant amount
          this.checkedVariant = item.checkedVariant;        // Selected variant data

          // Add the customized item to the dine-in order
          this.addItemToDineIn(item);
        } else {
          // Update existing order with modified item
          this.updateOrder(item);
        }
 
    });
  }

  /**
   * Adds or updates an item in the dine-in order
   * @param item The item to be added or updated in the order
   */
  addItemToDineIn(item: any) {

    // Search for existing item in KOT_data with matching properties
    const existingItemIndex = this.KOT_data.findIndex((search_item: any) => {
      // Initialize match flags
      let isChildOptionsMatch = true;  // For tracking child options match
      let isVariantsMatch = true;      // For tracking variants match

      // Check if basic item properties match (ID and prices)
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check if child options match (if they exist)
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check if variants match (if they exist)
      if (
        item.checkedVariant &&
        item.checkedVariant.length > 0 &&
        item.checkedVariant[0].variants
      ) {
        isVariantsMatch = item.checkedVariant[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.checkedVariant &&
              search_item.checkedVariant.length > 0 &&
              search_item.checkedVariant[0].variants &&
              search_item.checkedVariant[0].variants[index] &&
              search_item.checkedVariant[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true only if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    // Handle new item addition
    if (existingItemIndex === -1) {
      // Set default quantity to 1 if not specified
      item.qty = item.qty || 1;
      // Calculate item amount
      item.amount = this.calculateItemAmount(item);
      // Add new item to KOT_data
      this.KOT_data.push({ ...item });

      this.panel.open();
    }
    // Handle existing item update
    else {
      // Increment quantity
      this.KOT_data[existingItemIndex].qty += item.qty || 1;
      // Recalculate amount for updated quantity
      this.KOT_data[existingItemIndex].amount = this.calculateItemAmount(
        this.KOT_data[existingItemIndex]
      );
    }

    // Recalculate total amount including taxes
    this.calculateAmtWithTax();
  }


  /**
   * Calculates the total amount for an item including options
   * @param item The item object containing quantity (qty), base rate (s_rate), and any additional options
   * @returns The total calculated amount for the item
   */
  calculateItemAmount(item: any) {
    // Calculate base amount by multiplying quantity with item's base rate
    const baseAmount = item.qty * item.s_rate;

    // Calculate additional amount from options by multiplying quantity with options amount
    const optionsAmount = item.qty * this.optionarray_amount;

    // Return total amount by adding base amount and options amount
    return baseAmount + optionsAmount;
  }


  /**
   * Updates an order item with new calculations including variants, options, and taxes
   * @param item The order item to be updated
   */
  updateOrder(item: any) {
    // Store option and variant related amounts
    this.optionarray_amount = item.optionAmount;
    this.options_array = item.optionsData;
    this.checkedVariantAmount = item.checkedVariantAmount;
    this.checkedVariant = item.checkedVariant;

    // Calculate item amount based on variants and options
    item.amount =
      item.checkedVariantAmount > 0
        ? (item.checkedVariantAmount + item.optionAmount) *
        (item.qty > 0 ? item.qty : 1)
        : item.optionAmount > 0
          ? item.optionAmount + item.s_rate * item.qty
          : item.qty > 0
            ? item.s_rate * item.qty
            : item.s_rate;

    // Process tax calculations if transaction GST slab is disabled
    if (this.isTransactionGSTslab === false) {
      if (item.tax_mode === "GST") {
        if (this.isLocal) {
          // Calculate SGST for local transactions
          item.sgst_per = item.taxslab / 2;
          item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
          item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;

          // Calculate CGST for local transactions
          item.cgst_per = item.taxslab / 2;
          item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
          item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
        } else {
          // Calculate IGST for non-local transactions
          item.igst_per = item.taxslab;
          item.igst_amt = item.amount * (item.igst_per / 100) || 0;
          item.igst_amt = Math.round(item.igst_amt * 100) / 100;
        }

        // Calculate total tax (SGST + CGST + IGST)
        item.total_tax =
          item.sgst_amt || 0 + item.cgst_amt || 0 + item.igst_amt || 0;
      } else {
        // Calculate VAT if tax mode is not GST
        item.vat_per = item.taxslab;
        item.vat_amt = item.amount * (item.igst_per / 100) || 0;
        item.vat_amt = Math.round(item.igst_amt * 100) / 100;
        item.total_tax = item.vat_amt || 0;
      }

      // Calculate final total with tax
      item.total = item.amount + item.total_tax;

      // Update item instruction if form exists
      item.instruction = this.form && this.form.value.instruction;
    }

    // Update the order item
    this.updateOrderItem(item);
  }

  // update order item
  updateOrderItem(item: any) {

    const index = this.itemsArr.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.checkedVariant &&
        item.checkedVariant.length > 0 &&
        item.checkedVariant[0].variants
      ) {
        isVariantsMatch = item.checkedVariant[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.checkedVariant &&
              search_item.checkedVariant.length > 0 &&
              search_item.checkedVariant[0].variants &&
              search_item.checkedVariant[0].variants[index] &&
              search_item.checkedVariant[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    if (index !== -1) {
      this.KOT_data[index] = item;
    }

  }


  dineInData() {
    //---------session---------------------------------------------

    if (this.tableInfo.session !== null && this.tableInfo.session.length) {
      
      // Sets form fields with session data (customer details)
      this.form
        .get("customer_name")
        ?.setValue(this.tableInfo.session[0].name);
      this.form
        .get("contact_no")
        ?.setValue(this.tableInfo.session[0].contact_no);
      this.form.get("gstin")?.setValue(this.tableInfo.session[0].gstin);
      this.form.get("email")?.setValue(this.tableInfo.session[0].email);
      this.form.get("gstin")?.setValue(this.tableInfo.session[0].gstin);
    }

    //---------UnpaidInvoice----------------------------------------

    if (this.tableInfo.unpaidInvoiceList) {
      this.data = this.tableInfo.unpaidInvoiceList;
      this.dueInvoice = this.tableInfo.unpaidInvoiceList;
      this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
      this.dueInvoiceObj.sort = this.sort;
      this.dueInvoiceObj.paginator = this.paginator;
      if (this.dueInvoice && this.dueInvoice.length > 0) {
      }
    } else {
      this.dueInvoiceObj = new MatTableDataSource();
    }

    //---------TableListWithKOT------------------------------------

    const filteredKot = this.tableInfoDineIn.kot.filter(
      (item: any) => Object.keys(item).length !== 0
    );

    // Ensures the arrays for storing IDs and KOTs are initialized
    this.soIdArr = this.soIdArr || [];
    this.kotArr = this.kotArr || [];

    this.tableObj = filteredKot; // Assigns filtered KOTs to table object
    if (this.tableObj) {
      // Iterates over each KOT entry
      this.tableObj.forEach((kot: any) => {
        kot.added = true; // Marks the KOT as added

        // Stores transaction IDs and deep-copies KOT entries
        this.soIdArr.push(kot.transaction_id);
        this.kotArr.push(JSON.parse(JSON.stringify(kot)));

        // Processes items within the KOT
        kot.items_details.forEach((item: any) => {

          this.itemsArr.forEach((item: any) => {
            item.qty = parseFloat(item.qty)
            item.amount = parseFloat(item.s_rate + item.optionAmount) * item.qty;
          })

          // Searches for a matching item in the items array
          let pos = this.itemsArr.findIndex((search_item: any) => {
            let isChildOptionsMatch = true; // Default match state for child options
            let isVariantsMatch = true; // Default match state for variants

            // Checks basic item details for a match
            const isBasicMatch =
              search_item.item_id === item.item_id &&
              search_item.optionAmount === item.optionAmount &&
              search_item.checkedVariantAmount ===
                item.checkedVariantAmount;

            // Checks if child options match, if present
            if (
              item.optionsData &&
              item.optionsData.length > 0 &&
              item.optionsData[0].childOptions
            ) {
              isChildOptionsMatch = item.optionsData[0].childOptions.every(
                (childOpt: any, index: number) => {
                  return (
                    search_item.optionsData &&
                    search_item.optionsData.length > 0 &&
                    search_item.optionsData[0].childOptions &&
                    search_item.optionsData[0].childOptions[index] &&
                    search_item.optionsData[0].childOptions[index].title ===
                      childOpt.title
                  );
                }
              );
            }

            // Checks if variant groups match, if present
            if (
              item.variant_groups &&
              item.variant_groups.length > 0 &&
              item.variant_groups[0].variants
            ) {
              isVariantsMatch = item.variant_groups[0].variants.every(
                (variant: any, index: number) => {
                  return (
                    search_item.variant_groups &&
                    search_item.variant_groups.length > 0 &&
                    search_item.variant_groups[0].variants &&
                    search_item.variant_groups[0].variants[index] &&
                    search_item.variant_groups[0].variants[index].title ===
                      variant.title
                  );
                }
              );
            }

            // Returns true if all conditions match
            return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
          });

          if (pos == -1) {
            // Adds the item to the items array if no match is found
            this.itemsArr.push(item);

          } else {
            this.itemsArr[pos].qty =
              parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
            this.itemsArr[pos].amount =
              parseFloat(this.itemsArr[pos].qty) *
              parseFloat(
                this.itemsArr[pos].s_rate + this.itemsArr[pos].optionAmount
              );
            this.itemsArr[pos].igst_amt =
              (this.itemsArr[pos].amount * this.itemsArr[pos].igst_per) / 100;
            this.itemsArr[pos].cgst_amt =
              (this.itemsArr[pos].amount * this.itemsArr[pos].cgst_per) / 100;
            this.itemsArr[pos].sgst_amt =
              (this.itemsArr[pos].amount * this.itemsArr[pos].sgst_per) / 100;
          }
        });
      });
      // this.KOT_data = this.itemsArr;
      this.calculateAmtWithTax();
      if (this.tableObj.length > 0) {
        this.panel3.open()
      }
    }

    // -----------------Local Storage Management-----------------

    // Stores the isLocal flag in the local storage
    this.isLocal = this.tableInfo.isLocal;
    localStorage.setItem("isLocal", JSON.stringify(this.isLocal));

    //---------Table Name Handling------------------

    // Assigns the table name if present
    this.table_name = this.tableInfo.tableName
      ? this.tableInfo.tableName
      : null;

    //---------Rate Change Permission Handling------------------

    // Sets allowChangeRate based on the table info
    this.allowChangeRate = this.tableInfo.allowChangeRate ? true : false;
  }



  addItemsone(item: any) {

    if (!item.qty) {
      item.qty = 1
    }

    // Make sure KOT List is initialized
    if (!this.KOT_data) this.KOT_data = [];

    // Check if item with the same item_id already exists in the KOT_data list
    var result_items = this.KOT_data.filter((search_item: any) => {
      return search_item.item_id === item.item_id;
    });
    var found_item: any = null;
    var new_item_Added: any = false;

    // If item exists in KOT_data, assign it to found_item
    if (result_items && result_items.length > 0) found_item = result_items[0];

    // If item does not exist in KOT_data, add it as a new item
    if (!found_item) {
      found_item = item;
      new_item_Added = true;

      found_item = { ...found_item };
      if (!found_item.amount) {

        found_item.amount = +item.s_rate;
      } else {
        found_item.amount = item.amount;
      }

      found_item.instruction = "";


    } else {

      new_item_Added = false;
      found_item.qty = found_item.qty + item.qty;
      found_item.amount = found_item.qty * parseFloat(item.s_rate);
    }

    // If it's a new item, add it to the beginning of the KOT_data list
    if (new_item_Added) this.KOT_data.unshift(found_item);

    // Open the panel if it's not already expanded
    if (this.panel && !this.panel.expanded) {
      this.panel.open();
    }

    // Recalculate the total amount and tax
    this.calculateAmtWithTax();

}

calculateAmtWithTax(): void {
    // Ensure KOT_data exists and is an array
    if (!this.KOT_data || !Array.isArray(this.KOT_data)) {
      return;
    }


    const itemDetails = this.itemsArr && this.itemsArr.length > 0 ? this.itemsArr : this.KOT_data;

    let serviceCharge = this.serviceChargeAmount
    let gstSlabValue = this.gstSlab

    const body = {
      items_details: itemDetails, // List of items to calculate tax for
      discount_amt: this.form.value.discount_amt, // Discount amount from form
      other1_amt: this.form.value.other1_amt, // Additional charges (field 1)
      other2_amt: this.form.value.other2_amt, // Additional charges (field 2)
      taxslab: gstSlabValue, // GST slab value
      taxable_amt: 0, // Initial taxable amount
      service_charge: serviceCharge, // Service charge value
      total_amount: 0, // Total amount placeholder
      net_amount: 0, // Net amount placeholder
      roundoff_amt: 0, // Rounded-off amount placeholder
      gross_amount: 0, // Gross amount placeholder
      total_tax: 0, // Total tax placeholder
      sgst_per: 0, // SGST percentage placeholder
      sgst_amt: 0, // SGST amount placeholder
      cgst_per: 0, // CGST percentage placeholder
      cgst_amt: 0, // CGST amount placeholder
      igst_per: 0, // IGST percentage placeholder
      igst_amt: 0, // IGST amount placeholder
      vat_per: 0, // VAT percentage placeholder
      vat_amt: 0, // VAT amount placeholder
      tax_slab: 0, // Tax slab placeholder
      sign: 0, // Placeholder for sign
    };

    try {
      // Perform calculation using the calculation service
      const result = this.calculationService.calculateAmtWithTransTax(body);

      if (!result || Array.isArray(result)) {
        return;
      }

      // Map the result values to their corresponding form controls
      const formControlsMapping: Record<string, number> = {
        total_amount: result.total_amount,
        net_amount: result.net_amount,
        roundoff_amt: result.roundoff_amt,
        gross_amount: result.gross_amount,
        total_tax: result.total_tax && result.total_tax.toFixed(2),
        sgst_per: result.sgst_per,
        sgst_amt: result.sgst_amt,
        cgst_per: result.cgst_per,
        cgst_amt: result.cgst_amt,
        igst_per: result.igst_per,
        igst_amt: result.igst_amt,
        vat_per: result.vat_per,
        vat_amt: result.vat_amt,
        taxable_amt: result.taxable_amt,
        service_charge: result.service_charge
      };

      // Dynamically update the form controls with calculated values
      Object.entries(formControlsMapping).forEach(([controlName, value]) => {
        const control = this.form.get(controlName); // Get the form control by name
        if (control) {
          control.setValue(value, { emitEvent: false }); // Update control without emitting events
        } else {
          console.warn(`Form control "${controlName}" is not defined.`);
        }
      });
    } catch (error) {
      // Log any errors that occur during calculation
      console.error('Error calculating amount with tax:', error);
    }
}


// Main form for all transaction details
form: FormGroup = new FormGroup({
  customer_name: new FormControl("Name"), // Customer name field, default value is "Name"
  email: new FormControl(null), // Email field, default is null
  address: new FormControl(null), // Address field, default is null
  trans_no: new FormControl("Auto Generated"), // Transaction number, default is "Auto Generated"
  trans_date: new FormControl(new Date()), // Transaction date, default is current date
  ledger_id: new FormControl(null), // Ledger ID field, default is null
  table_id: new FormControl(null), // Table ID field, default is null
  gstin: new FormControl(null), // GSTIN field, default is null
  total_amount: new FormControl(0), // Total amount, default is 0
  total: new FormControl(0), // Total, default is 0
  total_tax: new FormControl(0), // Total tax, default is 0
  discount_amt: new FormControl(0), // Discount amount, default is 0
  roundoff_amt: new FormControl(0), // Round-off amount, default is 0
  other1_label: new FormControl(null), // Additional charge 1 label, default is null
  other2_label: new FormControl(null), // Additional charge 2 label, default is null
  other1_amt: new FormControl(0), // Additional charge 1 amount, default is 0
  other2_amt: new FormControl(0), // Additional charge 2 amount, default is 0
  net_amount: new FormControl(0), // Net amount, default is 0
  cash: new FormControl(0), // Cash payment, default is 0
  card: new FormControl(0), // Card payment, default is 0
  upi: new FormControl(0), // UPI payment, default is 0
  card_ref_no: new FormControl(null), // Card reference number, default is null
  advance: new FormControl(0), // Advance amount, default is 0
  balance: new FormControl(0), // Balance amount, default is 0
  contact_no: new FormControl(null), // Contact number, default is null
  taxslab: new FormControl(0), // Tax slab, default is 0
  vattaxslab: new FormControl(0), // VAT tax slab, default is 0
  dis_per: new FormControl(0), // Discount percentage, default is 0
  taxable_amt: new FormControl(0), // Taxable amount, default is 0
  sgst_per: new FormControl(0), // SGST percentage, default is 0
  sgst_amt: new FormControl(0), // SGST amount, default is 0
  cgst_per: new FormControl(0), // CGST percentage, default is 0
  cgst_amt: new FormControl(0), // CGST amount, default is 0
  igst_per: new FormControl(0), // IGST percentage, default is 0
  igst_amt: new FormControl(0), // IGST amount, default is 0
  vat_per: new FormControl(0), // VAT percentage, default is 0
  vat_amt: new FormControl(0), // VAT amount, default is 0
  gross_amount: new FormControl(0), // Gross amount, default is 0
  item_qty: new FormControl(null), // Item quantity, default is null
  item_rate: new FormControl(null), // Item rate, default is null
  item_amt: new FormControl(null), // Item amount, default is null
  cash_receive: new FormControl(0), // Cash received, default is 0
  upi_receive: new FormControl(0), // UPI received, default is 0
  instruction: new FormControl(""), // Special instructions, default is empty string
  service_charge: new FormControl(0), // Service charge amount, default is 0
  service_charge_per: new FormControl(0), // Service charge percentage, default is 0
});

  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      customer_name: "Name",
      email: null,
      address: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      table_id: null,
      gstin: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      balance: 0,
      contact_no: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      vat_per: 0,
      vat_amt: 0,
      gross_amount: 0,
      taxslab: 0,
      vattaxslab: 0,
      item_qty: null,
      item_rate: null,
      item_amt: null,
      cash_receive: 0,
      upi_receive: 0,
      instruction: null,
      service_charge: 0,
      service_charge_per: 0,
    });
    this.itemsArr = [];
    this.calculateAmtWithTax();
    this.soIdArr = [];
    if (this.kotArr) {
      this.kotArr
        .filter((x: any) => x.added === true)
        .forEach((x: any) => this.kotArr.splice(this.kotArr.indexOf(x), 1));
    }
    window.scroll(0, 0);
  }

// Handle changes in item quantity from the search item panel
onChangeItemQty(e: any, i: any) {
  let number: number = 0; // Initialize number to 0
  let optionAmount: number = 0; // Initialize option amount to 0

  // Check if KOT_data exists and has an item at the specified index
  if (this.KOT_data && this.KOT_data[i]) {
    let positiveQty = parseFloat(e.target.value) || 0; // Parse and validate the new quantity

    this.KOT_data[i].qty = positiveQty; // Update the quantity of the item

    // Check if the item has an option amount and set it
    if (this.KOT_data[i].optionAmount) {
      optionAmount = parseFloat(this.KOT_data[i].optionAmount);
    } else {
      optionAmount = number; // Use default number value if option amount is not present
    }

    // Calculate the new amount for the item
    this.KOT_data[i].amount =
      (parseFloat(this.KOT_data[i].s_rate) + optionAmount) *
      parseFloat(this.KOT_data[i].qty);

    // Call the calculation methods to update totals
    this.calculateAmtWithTax();
  } else {
    // Log an error if the item at the specified index is undefined
    console.error(
      `Item at index ${i} is undefined or itemsArr is not initialized.`
    );
  }
}



 // KOT item quantity increase
increase_qty(e: any, i: any) {
  // Check if KOT_data exists and has an item at the specified index
  if (this.KOT_data && this.KOT_data[i]) {
    let number: number = 0; // Initialize number to 0
    let optionAmount: number = 0; // Initialize option amount to 0
    let Quantity = parseFloat(e.qty); // Parse the current quantity

    // Handle quantity increment logic based on current value
    if (Quantity === 0.1) {
      this.KOT_data[i].qty = Quantity + 0.9; // Increment from 0.1 to 1.0
    } else if (Quantity >= 1) {
      this.KOT_data[i].qty = Quantity + 1; // Increment by 1 for quantities >= 1
    } else {
      this.KOT_data[i].qty = Quantity + 1; // General increment case
    }

    // Update option amount if present, otherwise use default number value
    if (this.KOT_data[i].optionAmount) {
      optionAmount = parseFloat(this.KOT_data[i].optionAmount);
    } else {
      optionAmount = number;
    }

      this.KOT_data[i].amount =
        (parseFloat(this.KOT_data[i].s_rate) + (optionAmount || 0)) *
        parseFloat(this.KOT_data[i].qty);

    // Recalculate totals with the updated quantity and amount
    this.calculateAmtWithTax();
  }
}

// KOT item quantity decrease
decrease_qty(e: any, i: any) {
  // Check if KOT_data exists and has an item at the specified index
  if (this.KOT_data && this.KOT_data[i]) {
    let number: number = 0; // Initialize number to 0
    let optionAmount: number = 0; // Initialize option amount to 0

    // Handle quantity decrement logic based on current value
    if (parseFloat(e.qty) <= 0.1) {
      this.KOT_data[i].qty = 0.1; // Minimum quantity set to 0.1
    } else if (e.qty === 1 || parseFloat(e.qty) === 1.0) {
      this.KOT_data[i].qty = (e.qty - 0.9).toFixed(1); // Decrease from 1.0 to 0.1
    } else if (parseFloat(e.qty) < 1) {
      // Decrease for quantities less than 1
      this.KOT_data[i].qty = parseFloat(e.qty) - 1;

      // Ensure quantity does not fall below 0.1
      if (
        parseFloat(this.KOT_data[i].qty) <= 0.0 ||
        this.KOT_data[i].qty <= 0
      ) {
        this.KOT_data[i].qty = 0.1;
      }
    } else {
      this.KOT_data[i].qty = (e.qty - 1).toFixed(1); // General decrement case
    }

    // Update option amount if present, otherwise use default number value
    if (this.KOT_data[i].optionAmount) {
      optionAmount = parseFloat(this.KOT_data[i].optionAmount);
    } else {
      optionAmount = number;
    }

    // Recalculate the amount for the item based on updated quantity
    this.KOT_data[i].amount =
      (parseFloat(this.KOT_data[i].s_rate) + optionAmount) *
      parseFloat(this.KOT_data[i].qty);

    // Recalculate totals with the updated quantity and amount
    this.calculateAmtWithTax();
  }
}

// For deleting KOT items
onDeleteItem1(i: any) {
  
  this.KOT_data.splice(i, 1); // Remove the item from KOT_data at the specified index
  this.form.get("dis_per")!.setValue(0); // Reset discount percentage to 0
  this.form.get("discount_amt")!.setValue(0); // Reset discount amount to 0
  this.form.get("service_charge_per")!.setValue(0); // Reset discount percentage to 0
  this.serviceChargeAmount = 0
  this.calculateAmtWithTax();// Recalculate totals after deletion
}

// For deleting invoice items
onDeleteItem(i: any) {

  this.itemsArr.splice(i, 1); // Remove the item from itemsArr at the specified index

  this.form.get("dis_per")!.setValue(0); // Reset discount percentage to 0
  this.form.get("discount_amt")!.setValue(0); // Reset discount amount to 0

  this.form.get("service_charge_per")!.setValue(0); // Reset discount percentage to 0
  this.serviceChargeAmount = 0

  this.calculateAmtWithTax(); // Recalculate totals after deletion
}


  // For selecting all KOTs at view orders
onSelectAllKOT(isChecked: boolean) {
  // Iterate through all KOTs in kotArr
  this.kotArr.forEach((kot: any) => {
    if (isChecked) { // If "Select All" checkbox is checked
      this.select_all = true; // Mark all KOTs as selected
      if (kot.added == false) { // If the KOT is not already added
        kot.added = true; // Mark KOT as added
        this.soIdArr.push(kot.transaction_id); // Add the KOT transaction ID to soIdArr
        kot.items_details.forEach((item: any) => { // Iterate through the items in the KOT
          // Find if the item with the same ID and rate exists in itemsArr
          var pos = this.itemsArr.findIndex(
            (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
          );
          if (pos == -1) {
            // If the item is not already in itemsArr, add it
            this.itemsArr.push(JSON.parse(JSON.stringify(item)));
          } else {
            // If the item exists, update the quantity and amount
            this.itemsArr[pos].qty =
              parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
            this.itemsArr[pos].amount =
              parseFloat(this.itemsArr[pos].qty) *
              parseFloat(this.itemsArr[pos].s_rate);
          }
        });
      }
    } else { // If "Select All" checkbox is unchecked
      this.select_all = false; // Mark all KOTs as unselected
      if (kot.added == true) { // If the KOT was added
        kot.added = false; // Mark KOT as not added
        // Remove the KOT transaction ID from soIdArr
        var kotIndex = this.soIdArr.findIndex(
          (e: any) => e === kot.transaction_id
        );
        this.soIdArr.splice(kotIndex, 1);
        kot.items_details.forEach((item: any) => { // Iterate through the items in the KOT
          // Find if the item with the same ID and rate exists in itemsArr
          var pos = this.itemsArr.findIndex(
            (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
          );
          if (pos !== -1) {
            if (this.itemsArr[pos].qty == item.qty) {
              // If item quantity matches, remove the item from itemsArr
              this.itemsArr.splice(pos, 1);
            } else {
              // Otherwise, decrease the quantity and recalculate the amount
              this.itemsArr[pos].qty =
                parseFloat(this.itemsArr[pos].qty) - parseFloat(item.qty);
              this.itemsArr[pos].amount =
                parseFloat(this.itemsArr[pos].qty) *
                parseFloat(this.itemsArr[pos].s_rate);
            }
          }
        });
      }
    }
  });
}

// For selecting individual KOTs
onChangeKOT(kot: any, isChecked: boolean) {
  if (isChecked) { // If the KOT checkbox is checked
    kot.added = true; // Mark the KOT as added
    this.soIdArr.push(kot.transaction_id); // Add the KOT transaction ID to soIdArr
    kot.items_details.forEach((item: any) => { // Iterate through the items in the KOT
      // Find if the item with the same ID and rate exists in itemsArr
      var pos = this.itemsArr.findIndex(
        (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
      );
      if (pos == -1) {
        // If the item is not already in itemsArr, add it
        this.itemsArr.push(JSON.parse(JSON.stringify(item)));
      } else {
        // If the item exists, update the quantity
        this.itemsArr[pos].qty =
          parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);

        // Recalculate the amount including additional option amount
        const a =
          parseFloat(this.itemsArr[pos].qty) *
          parseFloat(this.itemsArr[pos].s_rate);
        const b =
          parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;

        this.itemsArr[pos].amount = a + b;
      }
    });
  } else { // If the KOT checkbox is unchecked
    kot.added = false; // Mark the KOT as not added
    // Remove the KOT transaction ID from soIdArr
    var kotIndex = this.soIdArr.findIndex(
      (e: any) => e === kot.transaction_id
    );
    this.soIdArr.splice(kotIndex, 1);
    kot.items_details.forEach((item: any) => { // Iterate through the items in the KOT
      // Find if the item with the same ID and rate exists in itemsArr
      var pos = this.itemsArr.findIndex(
        (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
      );
      if (pos !== -1) {
        if (this.itemsArr[pos].qty == item.qty) {
          // If item quantity matches, remove the item from itemsArr
          this.itemsArr.splice(pos, 1);
        } else {
          // Otherwise, decrease the quantity and recalculate the amount
          this.itemsArr[pos].qty =
            parseFloat(this.itemsArr[pos].qty) - parseFloat(item.qty);
          const a =
            parseFloat(this.itemsArr[pos].qty) *
            parseFloat(this.itemsArr[pos].s_rate);
          const b =
            parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;
          this.itemsArr[pos].amount = a + b;
        }
      }
    });
  }
}

// Add item instructions to dine-in
instrucctiondata(item: any, n: any) {
  // Filter through KOT_data to find matching item and update its instruction
  this.KOT_data = this.KOT_data.filter((data: any) => {
    if (item.item_id == data.item_id) {
      data.instruction = n.value; // Update instruction value
    }
    return data; // Return updated item
  });
}


  // update addons and variant to any items.
  updateCustomisation(item: any) {
    let updateItem: any;
    updateItem = this.KOT_data.filter((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.checkedVariant &&
        item.checkedVariant.length > 0 &&
        item.checkedVariant[0].variants
      ) {
        isVariantsMatch = item.checkedVariant[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.checkedVariant &&
              search_item.checkedVariant.length > 0 &&
              search_item.checkedVariant[0].variants &&
              search_item.checkedVariant[0].variants[index] &&
              search_item.checkedVariant[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    const data = { item: updateItem[0], status: "update", source: "Dine-In" };

  // Open customization modal with prepared data
  this.openCustomization(data);
}

  // to download pdf
  downloadClick(dataObj: any, transNo: any) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
        this.panel3.open();
        // }
      },
      (result: any) => { }
    );
  }

  onAddKOT() {

    let validItemsKot: any[] = [];

    validItemsKot = this.KOT_data.filter((item: any) => item.amount > 0);

  this.waitforkot = true; // Indicate processing of KOT is ongoing
  let hasInvalidQuantity = false; // Flag for invalid quantity
  this.model_kot.net_amount = 0; // Initialize total net amount

  // Calculate net amount and check for invalid quantities
  validItemsKot.forEach((itm: any) => {
    if (itm.qty > 0) { // If the item has valid quantity
      this.model_kot.net_amount =
        parseFloat(this.model_kot.net_amount) +
        parseFloat(itm.amount) +
        (parseFloat(itm.sgst_amt) || 0) +
        (parseFloat(itm.cgst_amt) || 0) +
        (parseFloat(itm.igst_amt) || 0) +
        (parseFloat(itm.vat_amt) || 0);
    } else { // If the item has invalid quantity
      hasInvalidQuantity = true;
      notyf.error("Input Quantity"); // Display error notification
    }
  });

  // Stop further execution if there are invalid quantities
  if (hasInvalidQuantity) {
    this.waitforkot = false;
    return;
  }

  // If valid items are present with valid quantities
  if (validItemsKot?.length !== 0 && validItemsKot[0]?.qty > 0) {
    // Prepare model_kot with valid item details and metadata
    this.model_kot.items_details = validItemsKot;
    this.model_kot.table_id = this.table_id;
    this.model_kot.orderMode = "Dine-In";
    this.model_kot.ledger_id = 0;
    this.model_kot.instruction = this.form.get("instruction")!.value
      ? this.form.get("instruction")!.value
      : null;

    // Call API to add a sales order
    this.apiService.addSalesOrder(this.model_kot).subscribe(
      (result: any) => {
        if (result.success) { // If the API call was successful
          this.model_kot.trans_no = result.max_trans_value; // Assign transaction number
          this.model_kot.transaction_id = result.transaction_id; // Assign transaction ID
          this.model_kot.trans_type = "SalesOrder"; // Assign transaction type
          var tr_no = this.model_kot.trans_no;
          this.downloadClick(this.model_kot, tr_no); // Trigger PDF download
          this.model_kot = { trans_date: new Date(), net_amount: 0 }; // Reset model_kot
          this.KOT_data = []; // Clear KOT data
          validItemsKot = []; // Clear valid items
          notyf.success("New KOT added successfully"); // Display success notification
          this.waitforkot = false; // Stop processing indicator
          this.form.get("instruction")!.reset(); // Reset instruction field
          this.panel3.open(); // Open a UI panel
        } else { // If the API call failed
          notyf.error(result.message); // Display error notification
          this.waitforkot = false; // Stop processing indicator
        }
      },
      (error: any) => { // Handle API error
        console.log(error); // Log error to console
        notyf.error("Unable to add new KOT"); // Display error notification
        this.waitforkot = false; // Stop processing indicator
      }
    );
  } else { // If no valid items to process
    notyf.error("Add atleast one item to continue"); // Display error notification
    this.waitforkot = false; // Stop processing indicator
  }
}

// Refresh the component (currently unused)
private refreshComponent() {
  // this.load(); // Reload the component's data for the current user only
}

 // Add a new KOT without additional validations or filters
onOnlyAddKOT() {
  this.waitforkot = true; // Indicate KOT processing is ongoing
  this.model_kot.net_amount = 0; // Initialize net amount to 0

  // Check if any item in KOT_data has qty 0
  let hasInvalidQuantity = false;

    this.KOT_data.forEach((itm: any) => {
      if (itm.qty > 0) {
        // Calculate net amount for valid quantities
        this.model_kot.net_amount +=
          itm.amount +
          (itm.sgst_amt || 0) +
          (itm.cgst_amt || 0) +
          (itm.igst_amt || 0) +
          (itm.vat_amt || 0);
      } else {
        // Set flag to true if qty is 0 and show error
        hasInvalidQuantity = true;
        notyf.error("One of the quantities was not inputted");
      }
    });

  // If any item had an invalid quantity, stop further execution
  if (hasInvalidQuantity) {
    this.waitforkot = false;
    return;
  }

  // Check if there are items in KOT_data
  if ((this.KOT_data && this.KOT_data.length) !== 0) {
    // Prepare the model_kot with necessary details
    this.model_kot.items_details = this.KOT_data;
    this.model_kot.table_id = this.table_id;
    this.model_kot.orderMode = "Dine-In";
    this.model_kot.ledger_id = 0;
    this.model_kot.instruction = this.form.get("instruction")!.value
      ? this.form.get("instruction")!.value
      : null;

    // Call API to add the sales order
    this.apiService.addSalesOrder(this.model_kot).subscribe(
      (result: any) => {
        if (result) {
          // If successful, update transaction details
          this.model_kot.trans_no = result.max_trans_value;
          this.model_kot.transaction_id = result.transaction_id;
          this.model_kot.trans_type = "SalesOrder";

          // Reset model_kot and clear KOT data
          this.model_kot = { trans_date: new Date(), net_amount: 0 };
          this.KOT_data = [];
          notyf.success("New KOT added successfully"); // Show success notification
          this.waitforkot = false; // Stop processing indicator

          // Reset instruction field and open the panel
          this.form.get("instruction")!.reset();
          this.panel3.open();
        } else {
          // If unsuccessful, show error message
          notyf.error(result.message);
          this.waitforkot = false;
        }
      },
      (result: any) => {
        // Handle API error
        notyf.error("Unable to add new KOT");
        this.waitforkot = false;
      }
    );
  } else {
    // If no items are added, show an error notification
    notyf.error("Add at least one item to continue");
    this.waitforkot = false;
  }
}

// Edit an existing KOT from all orders panel
onEditKot(kot_id: any) {
  // Prepare data object with KOT details and settings
  var dataObj = {
    taxslab: this.form.value.taxslab,
    table_id: this.table_id,
    table_name: this.table_name,
    allowChangeRate: this.allowChangeRate,
    kot_id: kot_id,
  };

  // Open a dialog for editing KOT details
  this.dialog
    .open(RstKotComponent, {
      width: "900px", // Set dialog width
      data: dataObj, // Pass the data object to the dialog
      autoFocus: false, // Disable auto-focus
    })
    .afterClosed()
    .subscribe((result: any) => {
      if (result == "Edited") {
        // If KOT was edited, update the KOT list
        this.updateKotList(kot_id);
      }
    });
}

// Update the KOT list by removing the old KOT
updateKotList(kot_id: any) {
  // Find the index of the KOT with the specified transaction ID
  const index = this.kotArr.findIndex(
    (kot: any) => kot.transaction_id === kot_id
  );

  // If the KOT exists, remove it from the list
  if (index !== -1) {
    this.kotArr.splice(index, 1);
  }
}

// Convert a value to a floating-point number
convertFloat(val: any) {
  return parseFloat(val); // Parse and return the value as a float
}


// Handle input changes for customer phone number
onInput(event: any): void {
  event.preventDefault(); // Prevent default action of the event
  const inputElement = event.target as HTMLInputElement;
  let value = inputElement.value;

  // Remove non-numeric characters from the input
  value = value.replace(/[^0-9]/g, "");

  // Limit the length to 10 digits
  if (value && value.length > 10) {
    value = value.slice(0, 10);
    this.errorMessage = "Number should not exceed 10 digits."; // Show error if length exceeds 10
  } else {
    this.errorMessage = null; // Reset error message
  }

  this.inputValue = value; // Update the input value
  inputElement.value = value; // Set the input field value to the updated value

  this.searchByPhone(value); // Trigger phone search with the updated value
}

// Reset form fields to their default state
resetForm(): void {
  this.form.patchValue({
    customer_name: "",
    email: "",
    gstin: "",
  });
}

  searchByPhone(phone: string): void {
    this.inputValue = phone;
    this.apiService.fetchContactByPhone(phone).subscribe(
      (response: any) => {
        if (response.success && response.contact) {

          this.form.patchValue({
            ...this.form.value,
            customer_name: response.contact.name,
            email: response.contact.email_id,
            address: response.contact.shipping_address1,
            contact_no: response.contact.phone_number ? response.contact.phone_number : phone,
            gstin: response.contact.gstin,
          });
          // }
          this.isEditMode = true;
          this.editContactId = response.contact.id;
        } else {
          this.resetForm();
          this.isEditMode = false;
          this.editContactId = null;
        }
      },
      (error: any) => {
        console.error("Error searching contact by phone", error);
      }
    );
  }

  handleEnterKey(event: any): void {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of Enter key
      this.searchByPhone((event.target as HTMLInputElement).value); // Trigger search by phone
    }
  }

  // on select customer phone from list no invoice
  handleInputChange(value: string | null | undefined): void {

    if (!value || value.trim() === "") {
      this.listNumber = []; // Clear listNumber if input value is empty or null/undefined
    } else {
      this.apiService.searchNumber(value.trim()).subscribe(
        (response: any[]) => {
          this.listNumber = response || []; // Directly assign the response if it's an array, otherwise, clear the list
        },
        (error: any) => {
          console.error("API error:", error);
          this.listNumber = []; // Optionally, clear the list on error
        }
      );
    }
  }

// Handle focus event on the discount field
onDiscountFocus(isFocused: boolean) {
  if (!isFocused) {
    const discountControl: any = this.form.get("dis_per");
    // Reset the discount value to null if it is 0 or null when focus is lost
    if (discountControl.value === 0 || discountControl.value === null) {
      discountControl.setValue(null);
    }
  }
}

// Handle focus event on the service charge field
onServiceFocus(isFocused: boolean) {
  if (!isFocused) {
    const serviceChargeControl: any = this.form.get("service_charge_per");
    // Reset the service charge value to null if it is 0 or null when focus is lost
    if (serviceChargeControl.value === 0 || serviceChargeControl.value === null) {
      serviceChargeControl.setValue(null);
    }
  }
}

// Handle change in discount percentage
onDiscountPer(event: any) {
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const discountPer = this.form.get("dis_per")?.value || 0;
  const discountAmount = (totalAmount * discountPer) / 100;
  this.form.get("discount_amt")?.setValue(discountAmount); // Set the discount amount in the form
  const amountAfter = totalAmount - discountAmount;

  const serviceChargePer = this.form.get("service_charge_per")?.value || 0; // Parse the service charge percentage

  const serviceChargeAmount = (amountAfter * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount

  this.updateGrossAmount(); // Update gross amount after discount change
}

onServicePerOnLoad(serviceChargePer: number) {
  
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const serviceChargeAmount = (totalAmount * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount
  this.form.get("service_charge")?.setValue(serviceChargeAmount); // Set the service charge amount in the form
  this.updateGrossAmount(); // Update gross amount after service charge change
}

// Handle change in service charge percentage
onServicePer(event: any) {
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const discountAmount = this.form.get("discount_amt")?.value || 0;

  const amountAfter = totalAmount - discountAmount;

  const serviceChargePer = parseFloat(event.target.value) || 0; // Parse the service charge percentage
  this.form.get("service_charge_per")?.setValue(serviceChargePer); // Set the service charge percentage in the form
  const serviceChargeAmount = (amountAfter * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount.toFixed(2)
  this.form.get("service_charge")?.setValue(serviceChargeAmount); // Set the service charge amount in the form
  this.updateGrossAmount(); // Update gross amount after service charge change
}

// Update the gross amount based on various charges and discounts
updateGrossAmount(): void {
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const discountAmount = this.form.get("discount_amt")?.value || 0;
  const serviceChargeAmount = this.form.get("service_charge")?.value || 0;
  const taxAmount = this.form.get("total_tax")?.value || 0;
  const otherCharges =
    (this.form.get("other1_amt")?.value || 0) +
    (this.form.get("other2_amt")?.value || 0);
  // Calculate the gross amount
  const grossAmount = totalAmount - discountAmount + taxAmount + otherCharges + serviceChargeAmount;
  const formattedGrossAmount = parseFloat(grossAmount).toFixed(2); // Format the gross amount to 2 decimal places
  this.form.get("gross_amount")?.setValue(formattedGrossAmount); // Set the gross amount in the form
  this.updateNetAmount(); // Update net amount based on the gross amount
}

// Update the net amount based on the gross amount and round-off value
updateNetAmount(): void {
  const grossAmount = this.form.get("gross_amount")?.value || 0;
  const roundOff = this.form.get("roundoff_amt")?.value || 0;
  const netAmount = grossAmount + roundOff;
  this.form.get("net_amount")?.setValue(netAmount); // Set the net amount in the form
  this.calculateAmtWithTax(); // Recalculate the amount with tax
}

// Handle discount amount change and reset discount percentage
onDiscountAmt(event: any) {
  const totalAmount = this.form.get("total_amount")?.value || 0;
  const discountAmount = this.form.get("discount_amt")?.value || 0;
  const amountAfter = totalAmount - discountAmount;
  const serviceChargePer = this.form.get("service_charge_per")?.value || 0;
  const serviceChargeAmount = (amountAfter * serviceChargePer) / 100;
  this.serviceChargeAmount = serviceChargeAmount.toFixed(2)
  this.form.get("dis_per")!.setValue(0); // Reset discount percentage to 0
  this.calculateAmtWithTax(); // Recalculate amount with the new discount value
}

// Add other prices to the total and recalculate amount with tax
onOtherPrice() {
  if (this.form?.value?.total_amount) {
    this.calculateAmtWithTax(); // Recalculate amount with tax when other price is added
  }
}


  onCheckboxChange() {
    if ((this.form.controls["dis_per"] && this.form.controls["dis_per"].value === 0) || null) {
      if (!this.showValidationInput) {
        this.apiService.ncValidation().subscribe(
          (response: any) => {
            if (response.status === true) {
              this.showValidationDiscount = false;
              this.non_receivable = true;
            }
          },
          (error: any) => {
            console.error("API error:", error);
          }
        );
      } else {
        this.non_receivable = false;
      }
    } else {
      notyf.error("Select either Discount or No Cost");
    }
    this.showValidationInput = !this.showValidationInput;
    this.showValidationDiscount = !this.showValidationDiscount;
    if (!this.showValidationInput) {
      this.resetOTPFields();
    }

  }

  // reset otp field
  resetOTPFields() {
    this.validationForm.reset();
    this.otpVerified = false;
    this.otpInvalid = false;
    this.showValidationDiscount = false;
  }

  onSubmit() {
    const contact_no = this.form?.value?.contact_no;
    const email_id = this.form?.value?.email;
    const customer_name = this.form?.value?.customer_name;

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let isValid = true; // Flag to track form validity

    // Validate contact number length (assuming numeric input)
    if (contact_no && (!/^\d+$/.test(contact_no) || (contact_no && contact_no.length) < 10)) {
      notyf.error("Contact Number must be at least 10 digits long");
      isValid = false;
    }
    if (email_id && !emailRegex.test(email_id)) {
      notyf.error("Please Provide a Valid Email Address");
      isValid = false;
    }
    if (!customer_name) {
      notyf.error("Customer Name is required");
      isValid = false;
    }

    if (!isValid) {
      return; // Stop further execution if validation fails
    }
    this.saveInvoiceData("submit");
  }

  SaveAndPrint() {
    this.saveInvoiceData("print");
  }

  private saveInvoiceData(action: string) {
    // Common logic to save invoice data
    this.saveContact();
    if (this.showValidationInput) {
      if (this.validationForm.valid) {
        this.otpVerified = false;
        this.otpInvalid = false;
        this.formSubmitted = true;
        this.showValidationInput = false;
        this.showValidationDiscount = false;
        this.Savedata(action);
      }
    } else {
      this.Savedata(action);
    }
  }

  saveContact(): void {
    this.contactNo = parseFloat(this.contactForm?.value.contact_no);
    if (this.form.valid) {
      const contactData = {
        name: this.form?.value.customer_name,
        email_id: this.form?.value.email,
        contact_no: this.contactNo,
        gstin: this.form?.value.gstin,
        shipping_address1: this.form?.value.address,
      };
      
      if (this.isEditMode && this.editContactId) {
        this.apiService
          .editContact({ ...contactData, id: this.editContactId })
          .subscribe(
            (response: any) => {
              if (response.success) {
                // this.resetForm();
              }
            },
            (error: any) => {
              console.error("Error editing contact", error);
            }
          );
      } else {
        this.apiService.addContact(contactData).subscribe(
          (response: any) => {
            if (response.success) {
              // this.resetForm();
            }
          },
          (error: any) => {
            console.error("Error adding contact", error);
          }
        );
      }
    }
  }

  SaveAndEbill() {
    const name = this.form?.value?.customer_name;
    const email_id = this.form?.value?.email;
    const contact_no = this.form?.value?.contact_no;

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check for empty fields
    if (!name) {
      notyf.error("Customer Name is required");
      return; // Stop further execution
    }

    if (!email_id && !contact_no) {
      notyf.error("Please Provide Either Email or Phone Number");
      return; // Stop further execution
    }

    if (email_id && !emailRegex.test(email_id)) {
      notyf.error("Please Provide a Valid Email Address");
      return; // Stop further execution
    }

    // Validate contact number length (assuming numeric input)
    if (contact_no && (!/^\d+$/.test(contact_no) || (contact_no && contact_no.length) < 10)) {
      notyf.error("Contact Number must be at least 10 digits long");
      return; // Stop further execution
    }

    // If all validations pass, proceed to save invoice data
    if (email_id || contact_no) {
      this.saveInvoiceData("ebill");
      notyf.success("Ebill sent successfully");
    } else {
      notyf.error("Please Provide Either Email or Phone Number");
    }
  }

  // to open receipt dialog
  receiptDialog(e: any) {
    const dialogRef: MatDialogRef<ReceiptDialogComponent> = this.dialog.open(
      ReceiptDialogComponent,
      {
        data: e.display_trans_no,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.requestReset()
      }
    });

    // After the receipt is generated, refresh only this user's component
    this.refreshComponent();
  }

  private Savedata(action: string) {
    this.waitforinvoice = true;
    let orderMode = "Dine In"


    let validItems: any[] = []

    // Ensure itemsArr exists and all items have valid amounts
    if (this.itemsArr) {
      validItems = this.itemsArr.filter((item: any) => item.amount > 0);
    }

    // Conditionally modify eBillData
    let eBillData;

    this.ebillData = this.tableInfo.ebill;

    if (action === "print") {
      // For printing action
      eBillData = this.ebillData.map((item: any) => ({
        ...item,
        checkbox_value: false,
      }));
    } else {
      // For eBilling action (including default case for 'submit' action)
      eBillData = this.ebillData;
    }
    
    if (this.form.valid && this.validateEmailAndMobile()) {
      this.invoice_no = null;
      this.form.get("trans_no")!.setValue(this.invoice_no);

      this.transDataObj = this.form.value;
      this.transDataObj.contact_no = this.contactNo;
      this.transDataObj.items_details = validItems;
      this.transDataObj.soIdArr = [...new Set(this.soIdArr)];
      this.transDataObj.ledger_id = 0;
      this.transDataObj.table_id = this.table_id;
      this.transDataObj.sale_type = "cash";
      this.transDataObj.orderMode = orderMode;
      this.transDataObj.non_Receivable = this.non_receivable;
      this.transDataObj.eBillData = action == "ebill" ? eBillData : null;
      this.transDataObj.shipping_address1 = this.form?.value.address;

      this.apiService.addInvoice(this.transDataObj).subscribe(
        (result: any) => {
          if (result.success) {
            this.form.value.net_amount = result.net_amount;
            this.form.value.balance_amt = result.balance_amt;
            this.form.value.total_amount = result.total_amount;
            this.form.value.balance = result.balance;
            this.form.value.taxable_amt = result.taxable_amt;
            this.transDataObj.transaction_id = result.transaction_id;
            this.transDataObj.display_trans_no = result.display_trans_no;
            this.transDataObj.trans_type = "Invoice";
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.initializeFormGroup();
            this.contactForm.setValue({contact_no: null})
            if (action === "ebill" || action === "print") {
              this.apiService.downloadPdf(this.transDataObj).subscribe(
                (result: any) => {
                  let blob = new Blob([result], {
                    type: "application/pdf",
                  });
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download =
                    "invoice" + this.transDataObj.display_trans_no + ".pdf";
                  link.click();
                  window.URL.revokeObjectURL(link.href);
                },
                (result: any) => { }
              );
            }
            notyf.success("New Invoice added successfully");
            this.initializeFormGroup();
            // this.getPendingInvoice()
            if (this.form.controls["advance"]?.value) {
              this.initializeFormGroup();
              // this.load();
              // this.requestReset();
              this.resetData();


            }
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.waitforTakeaway = false;
            // this.requestReset();
            this.resetData();


          } else {
            window.scroll(0, 0);
            notyf.error(result.message);
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.waitforTakeaway = false;
          }
        },
        (result: any) => {
          this.initializeFormGroup();
          notyf.error("unable to add invoice");
          this.waitforinvoice = false;
          this.non_receivable = false;
          this.showValidationInput = false;
          this.showValidationDiscount = false;
          this.formSubmitted = false;
          this.validationForm.reset();
          this.validationForm.get("validationCode")!.setValue("");
          this.waitforTakeaway = false;
        }
      );

      this.panel4.open();
      this.panel3.close();

    } else {
      window.scroll(0, 0);
      this.waitforinvoice = false;
      this.non_receivable = false;
      this.showValidationInput = false;
      this.showValidationDiscount = false;
      this.validationForm.reset();
      this.formSubmitted = false;
      this.validationForm.get("validationCode")!.setValue("");
      this.waitforTakeaway = false;
    }

    // Only change panel states if no validation errors occurred
    if (this.form.valid) {

      this.panel4.open();
      this.panel3.close();

    }
  }


  validateEmailAndMobile() {
    const email = this.form?.value.email;
    const contact_no = this.form?.value.contact_no;

    contact_no && contact_no.length !== 10
      ? notyf.error("Contact number must be exactly 10 digits")
      : null;

    // if (!contact_no && !email) {
    //   return true; // Prevent further execution if validation fails
    // }

    const customername = this.form?.value.customer_name;
    if (!customername) {
      notyf.error("Customer name is required");
      return false;
    }

    // Email validation (basic regex)
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (email && !emailPattern.test(email)) {
      notyf.error("Please enter a valid email address");
      return false;
    }
    return true;
  }


  billsdata: any = [];
  wait: boolean = false;
  paymentMode: any;
  receipt: any = [];

  onReceipt(row: any, mode: string) {
    // Assign the full net amount to the selected payment mode

    if (mode === "cash") {
      this.form.controls['cash'].setValue(row.net_amount);
      this.form.controls['card'].setValue(0);
      this.form.controls['upi'].setValue(0);
    } else if (mode === "card") {
      this.form.controls['cash'].setValue(0);
      this.form.controls['card'].setValue(row.net_amount);
      this.form.controls['upi'].setValue(0);
    } else if (mode === "upi") {
      this.form.controls['cash'].setValue(0);
      this.form.controls['card'].setValue(0);
      this.form.controls['upi'].setValue(row.net_amount);
    }

    const amountReceived =
      (this.form.controls['cash'].value || 0) +
      (this.form.controls['card'].value || 0) +
      (this.form.controls['upi'].value || 0);

    if (row.net_amount !== amountReceived) {
      notyf.error("Paid Amount Should Be Equal To Due Amount");
      return;
    }

    this.data = row;
    this.wait = true;
    this.paymentMode = mode; // Set payment mode based on the button clicked

    if (this.data) {
      this.billsdata.push({
        transaction_id: this.data.transaction_id,
        trans_date: this.data.trans_date,
        trans_no: this.data.trans_no,
        display_trans_no: this.data.display_trans_no,
        customer_name: this.data.customer_name && this.data.customer_name,
        table_id: this.data.table_id,
        table_name: "T2",
        net_amount: this.data.net_amount,
        discount_amt: this.data.discount_amt,
        advance: amountReceived,
        paid: amountReceived.toString(),
        due: amountReceived, // No due amount since full amount is paid
        bill_amt: this.data.net_amount,
      });

      this.receipt.push({
        bills: this.billsdata,
        ledger_id: 17,
        ledger_id2: 1,
        payment_mode: this.paymentMode,
        total_amount: amountReceived,
        trans_date: this.data.trans_date,
        trans_no: null,
        trans_type: "Receipt",
        transaction_id: this.data.transaction_id,
        cash: this.form.controls['cash'].value,
        card: this.form.controls['card'].value,
        upi: this.form.controls['upi'].value,
      });

      this.apiService.addReceipt(this.receipt[0]).subscribe(
        (result: any) => {
          if (result.success) {
            this.receipt.trans_no = result.max_trans_value;
            this.receipt.transaction_id = result.transaction_id;
            this.receipt.trans_type = "Receipt";
            this.receipt.bills = null;
            this.printClick();
            this.data = undefined;
            this.billsdata = [];
            this.receipt = [];
            notyf.success("Receipt created successfully");
            this.wait = false;
            this.load();
          } else {
            notyf.error(result.message);
            this.wait = false;
          }
        },
        (error) => {
          notyf.error("Unable to create Receipt");
          this.wait = false;
        }
      );
    } else {
      notyf.error("No invoice Found");
      this.wait = false;
    }
  }

  printClick() {
    this.model.trans_data = this.receipt;
    this.model.trans_type = "Receipts";
    this.apiService.downloadPdf(this.model).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "recepit_data" + new Date().toLocaleDateString() + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }

}
